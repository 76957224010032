import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import styles from './Form.module.css';

export const DatePicker = ({ error, ...rest }) => (
  <div className={styles.datePicker}>
    <KeyboardDatePicker {...rest} />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);
