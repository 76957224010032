import React from 'react';
import styles from './Form.module.css';

export const Textarea = ({ label, error, ...rest }) => (
  <label className={styles.label}>
    {label}
    <textarea className={styles.input} {...rest} />
    {error && <p className={styles.error}>{error}</p>}
  </label>
);
