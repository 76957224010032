import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_FOLLOWERS = 'followers/GET_FOLLOWERS';
export const GET_FOLLOWINGS = 'followers/GET_FOLLOWINGS';

// action creators
export const getFollowers = createAction(GET_FOLLOWERS, api.followers.getFollowers);
export const getFollowings = createAction(GET_FOLLOWINGS, api.followers.getFollowers);

// reducer
const followersReducer = handleActions(
  {
    [`${GET_FOLLOWERS}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_FOLLOWERS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => {
      const followers = data.map((item) => item.follower);

      return {
        ...rest,
        list: followers,
        isLoading: false,
      };
    },

    [`${GET_FOLLOWINGS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => {
      const followings = data.map((item) => item.user);

      return {
        ...rest,
        list: followings,
        isLoading: false,
      };
    },

    [`${GET_FOLLOWERS}_ERROR`]: () => initialState,
  },
  initialState,
);

export default followersReducer;
