import React from 'react';
import styles from './Form.module.css';

export const RadioButton = ({ label, error, ...rest }) => (
  <label className={styles.radioLabel}>
    <input type='radio' className={styles.radio} {...rest} />
    {label}
    {error && <p className={styles.error}>{error}</p>}
  </label>
);
