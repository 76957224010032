import React, { useCallback } from 'react';
// store
import { useSelector } from 'react-redux';
import { getStores } from '../../store/modules/stores';
// formik
import * as Yup from 'yup';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { genderOptions, dealTypeOptions, ageGroupOptions } from './formOptions';
// components
import { Table } from '../../components';
// components
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import {
  Input,
  Textarea,
  Select,
  RadioButton,
  ImageUploader,
  DateTimePicker,
  Checkbox,
} from '../../components';
// utils
import { resizeImage } from '../../utils';
// styles
import styles from './DealsDetails.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    width: 100,
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    width: '80%',
  },
];

const actions = ({ onAddTag }, { values }) => [
  (tag) => ({
    name: 'Add',
    onClick: onAddTag,
    isDisabled: !!values.find((value) => value.id === tag.id),
  }),
];

const initialValues = {
  photo: null,
  name: '',
  description: '',
  url: '',
  discountCode: '',
  dealType: {},
  startDate: null,
  expirationDate: null,
  genders: [],
  ageGroups: [],
  countries: [],
  needNotify: true,
  tags: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  url: Yup.string().url('Invalid URL').required('Link is required'),
  startDate: Yup.string().required('Srart date is required').nullable(),
  dealType: Yup.object().test('dealType', 'Deal Type is required', (value) => value.id),
  needNotify: Yup.boolean().defined(),
});

export const DealsDetailsForm = ({ values, countries, onSubmit }) => {
  const stores = useSelector((state) => state.stores);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values
      ? {
          ...values,
          photoPreview: values?.photo?.path,
        }
      : initialValues,
    onSubmit,
    validationSchema,
  });

  const handleChangeImage = async (images, imageURL) => {
    const resizedImage = await resizeImage(images[0], 1000, 580);

    formik.setFieldValue('photo', resizedImage);
    formik.setFieldValue('photoPreview', imageURL[0]);
  };

  const handlerSearchParams = useCallback(({ params }) => ({ $and: [params] }), []);

  return (
    <FormikProvider value={formik}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <ImageUploader
          withIcon={false}
          withLabel={false}
          withPreview
          imgExtension={['.jpg', '.png', '.jpeg']}
          maxFileSize={5242880}
          onChange={handleChangeImage}
          name='photo'
          singleImage
          defaultImages={formik.values.photoPreview ? [formik.values.photoPreview] : undefined}
        />

        <Input
          label='Name'
          name='name'
          placeholder='Name'
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          isRequired
        />
        <Textarea
          label='Description'
          name='description'
          placeholder='Description'
          onChange={formik.handleChange}
          value={formik.values.description}
          error={formik.touched.description && formik.errors.description}
          onBlur={formik.handleBlur}
        />

        <Input
          label='Link to store'
          name='url'
          placeholder='Link'
          onChange={formik.handleChange}
          value={formik.values.url}
          error={formik.touched.url && formik.errors.url}
          onBlur={formik.handleBlur}
          isRequired
        />
        <Input
          label='Discount code'
          name='discountCode'
          placeholder='Discount code'
          onChange={formik.handleChange}
          value={formik.values.discountCode}
        />
        <div className={styles['radio-group']}>
          <label className={styles.label}>Deal type</label>

          <div className={styles['radio-wrapper']}>
            {dealTypeOptions.map((option) => (
              <RadioButton
                key={option.id}
                label={option.name}
                name='dealType'
                value={option}
                checked={formik.values.dealType?.name === option.name}
                onChange={() => formik.setFieldValue('dealType', option)}
              />
            ))}
          </div>

          {formik.touched.dealType && formik.errors.dealType && (
            <p className={styles.error}>{formik.errors.dealType}</p>
          )}
        </div>

        <DateTimePicker
          variant='dialog'
          ampm={false}
          name='startDate'
          label='Start date'
          value={formik.values.startDate}
          onChange={(date) => formik.setFieldValue('startDate', date && date.toISO())}
          format='yyyy/MM/dd HH:mm'
          className={styles.datepicker}
          error={formik.touched.startDate && formik.errors.startDate}
        />

        <KeyboardDateTimePicker
          variant='dialog'
          ampm={false}
          name='expirationDate'
          label='Expiration date'
          value={formik.values.expirationDate}
          onChange={(date) => formik.setFieldValue('expirationDate', date && date.toISO())}
          format='yyyy/MM/dd HH:mm'
          className={styles.datepicker}
        />

        <Select
          isMulti
          label='Genders'
          name='genders'
          options={genderOptions}
          value={formik.values.genders}
          onChange={(value) => formik.setFieldValue('genders', value)}
        />

        <Select
          isMulti
          label='Age groups'
          name='ageGroups'
          options={ageGroupOptions}
          value={formik.values.ageGroups}
          onChange={(value) => formik.setFieldValue('ageGroups', value)}
        />

        <Select
          isMulti
          label='Countries'
          name='countries'
          options={countries}
          value={formik.values.countries}
          onChange={(value) => formik.setFieldValue('countries', value)}
        />

        <div className={styles.checkboxWrapper}>
          <Checkbox
            label='Need Notification'
            value={formik.values.needNotify}
            checked={formik.values.needNotify}
            onChange={() => formik.setFieldValue(`needNotify`, !formik.values.needNotify)}
            className={styles.checkbox}
          />
        </div>

        <FieldArray
          name='tags'
          render={(arrayHelpers) => (
            <div className={styles.tagsWrapper}>
              <div className={styles.tagsTitle}>Group of interest</div>

              <div className={styles.tagsBody}>
                {formik.values.tags.map((value, index) => (
                  <div key={index} className={styles.tag}>
                    {value.name}
                    <button
                      type='button'
                      onClick={() => arrayHelpers.remove(index)}
                      className={styles.buttonRemove}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>

              <Table
                data={stores}
                getData={getStores}
                searchParams={handlerSearchParams}
                columns={columns}
                actions={actions(
                  { onAddTag: (rowData) => arrayHelpers.push(rowData) },
                  { values: formik.values.tags },
                )}
              />
            </div>
          )}
        />

        <button type='submit' className={styles['button-submit']}>
          Save
        </button>
      </form>
    </FormikProvider>
  );
};
