import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
// store
import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers,
  deleteUser,
  updateUser,
  handlePay,
  getStatistic,
} from '../../store/modules/users';
import { getAuthToken } from 'utils/authData';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
import { Button, Grid, CircularProgress } from '@material-ui/core';
// instance
import { baseURL } from 'utils/axios.instance';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './Influencers.module.css';

export const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'First name',
    field: 'firstName',
    emptyValue: 'N/A',
  },
  {
    title: 'Last name',
    field: 'lastName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Email',
    field: 'email',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Followers',
    field: 'followersCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Following',
    field: 'followingCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Gifts',
    field: 'giftCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Country',
    field: 'country.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Last activity',
    field: 'updatedAt',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Clicks',
    field: 'statistic.clicks',
    type: 'numeric',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Sales',
    field: 'statistic.sales',
    type: 'numeric',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Available for payment',
    field: 'statistic.availablePaid',
    type: 'numeric',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ statistic: { availablePaid } }) => availablePaid.toFixed(2),
  },
  {
    title: 'Paid to user',
    field: 'statistic.paid',
    type: 'numeric',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ statistic: { paid } }) => paid.toFixed(2),
  },
  {
    title: 'Unpaid total',
    field: 'statistic.unpaid',
    type: 'numeric',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ statistic: { unpaid } }) => unpaid.toFixed(2),
  },
  {
    title: 'Last Payment Date',
    field: 'statistic.lastInternalPaidAt',
    type: 'datetime',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({
  onUserDetails,
  onUserGifts,
  onDeleteUser,
  toggleActivateUser,
  onUserFollowers,
  onUserFollowings,
  onBanUser,
  onUserDeals,
  onUserPay,
}) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
  () => ({
    name: 'Gifts',
    onClick: onUserGifts,
  }),
  (user) => ({
    name: 'Followers',
    onClick: onUserFollowers,
    isDisabled: !user.followersCount,
  }),
  (user) => ({
    name: 'Following',
    onClick: onUserFollowings,
    isDisabled: !user.followingCount,
  }),
  () => ({
    name: 'User Deals',
    onClick: onUserDeals,
  }),
  () => ({
    name: 'Pay',
    onClick: onUserPay,
  }),
  (user) => ({
    name: user.status.name === 'Inactive' ? 'Activate user' : 'Deactivate user',
    onClick: toggleActivateUser,
  }),
  (user) => ({
    name: user.isBanned ? 'Unban/Show' : 'Ban/Hide',
    onClick: onBanUser,
  }),
  () => ({
    name: 'Delete user',
    onClick: onDeleteUser,
  }),
];

export const Influencers = ({ history, match }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();
  const [searchParams, setSearchParams] = useState({});

  const authToken = getAuthToken();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getStatistic());
    // eslint-disable-next-line
  }, []);

  const onUserDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  const onUserFollowers = (rowData) => history.push(`${match.url}/${rowData.id}/followers`);

  const onUserFollowings = (rowData) => history.push(`${match.url}/${rowData.id}/followings`);

  const onUserGifts = (rowData) => history.push(`${match.url}/${rowData.id}/gifts`);

  const onUserDeals = (rowData) => history.push(`${match.url}/${rowData.id}/deals`);

  const toggleActivateUser = (rowData) =>
    dispatch(
      updateUser(rowData.id, {
        status: rowData.status.name === 'Inactive' ? { id: 1 } : { id: 2 },
      }),
    );

  const onBanUser = (rowData) =>
    dispatch(
      updateUser(rowData.id, {
        isBanned: !rowData.isBanned,
      }),
    );

  const onDeleteUser = (rowData, fetchData) =>
    dispatch(deleteUser(rowData.id)).then(() =>
      fetchData(users.count === 1 ? users.page - 1 : users.page, users.count),
    );

  const onUserPay = (rowData, fetchData) =>
    dispatch(handlePay(rowData.id)).then(() =>
      fetchData(users.count === 1 ? users.page - 1 : users.page, users.count),
    );

  const handlerSearchParams = useCallback(
    ({ params, search }) => {
      const userSearch = {
        $and: [
          !search && {
            createdAt: {
              $gte: startDate.toISOString(),
            },
          },
          !search && {
            createdAt: {
              $lte: endDate.toISOString(),
            },
          },
          {
            '"role"."id"': 5,
          },
          params,
        ],
      };

      setSearchParams(userSearch);

      return userSearch;
    },
    [startDate, endDate],
  );

  const exportUsersLink = useMemo(
    () => `${baseURL}/users/csv?bearerToken=${authToken}&s=${JSON.stringify(searchParams)}`,
    [authToken, searchParams],
  );

  return (
    <div>
      <h1 className={styles.title}>Influencers</h1>

      <Grid container direction='column' className={styles.statisticContainer}>
        <h2>Total statistic</h2>
        {users.influencersStatistic ? (
          <Grid container spacing={1}>
            <Grid container direction='column' item xs={2}>
              <span className={styles.statisticHeading}>Clicks</span>
              <span className={styles.statisticText}>{users.influencersStatistic.clicks}</span>
            </Grid>
            <Grid container direction='column' item xs={2}>
              <span className={styles.statisticHeading}>Sales</span>
              <span className={styles.statisticText}>{users.influencersStatistic.sales}</span>
            </Grid>

            <Grid container direction='column' item xs={3}>
              <span className={styles.statisticHeading}>Available for payment</span>
              <span className={styles.statisticText}>
                {users.influencersStatistic.availablePaid.toFixed(2)}
              </span>
            </Grid>
            <Grid container direction='column' item xs={1}>
              <span className={styles.statisticHeading}>Paid</span>
              <span className={styles.statisticText}>
                {users.influencersStatistic.paid.toFixed(2)}
              </span>
            </Grid>
            <Grid container direction='column' item xs={1}>
              <span className={styles.statisticHeading}>Unpaid</span>
              <span className={styles.statisticText}>
                {users.influencersStatistic.unpaid.toFixed(2)}
              </span>
            </Grid>
            <Grid container direction='column' item xs={3}>
              <span className={styles.statisticHeading}>Last Payment Date</span>
              <span className={styles.statisticText}>
                {users.influencersStatistic.lastInternalPaidAt
                  ? DateTime.fromISO(users.influencersStatistic.lastInternalPaidAt).toFormat('F')
                  : '-'}
              </span>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify='center'>
            <CircularProgress />
          </Grid>
        )}
      </Grid>

      <div className={styles.actions}>
        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <Button color='primary' variant='contained' size='small' className={styles.exportButton}>
          <a href={exportUsersLink}>Export users</a>
        </Button>
      </div>

      <Table
        data={users}
        columns={columns}
        getData={getUsers}
        searchParams={handlerSearchParams}
        defaultSort='createdAt,DESC'
        actions={actions({
          onUserDetails,
          onUserGifts,
          onDeleteUser,
          toggleActivateUser,
          onUserFollowers,
          onUserFollowings,
          onBanUser,
          onUserDeals,
          onUserPay,
        })}
        options={{ exportFileName: 'Users' }}
      />
    </div>
  );
};
