import React, { useState, useCallback } from 'react';
// components
import TextField from '@material-ui/core/TextField';
// store
import { useSelector, useDispatch } from 'react-redux';
import { updateCategory } from '../../store/modules/categories';
// styles
import styles from './CategoriesPosition.module.css';

export const CategoriesPositionPage = ({ history }) => {
  const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  const [positions, setPositions] = useState(
    categories.list.sort((a, b) => a.position - b.position),
  );

  const handleChange = useCallback((position, category) => {
    setPositions((prev) =>
      prev.map((el) => (el.id === category.id ? { ...el, position: +position, isEdit: true } : el)),
    );
  }, []);

  const handleSubmit = useCallback(async () => {
    await positions.forEach((el) => {
      if (el.isEdit) {
        delete el.isEdit;
        delete el.isError;
        dispatch(updateCategory(el.id, el));
      }
    });

    history.goBack();
  }, [positions, dispatch, history]);

  const handleSave = useCallback(() => {
    const map = [];

    setPositions((prev) =>
      prev.map((category) => {
        if (!map.includes(category.position) && category.position) {
          map.push(category.position);
          category.isError = false;
        } else {
          category.isError = true;
        }

        return category;
      }),
    );

    if (map.length === positions.length) {
      handleSubmit();
    }
  }, [positions, handleSubmit]);

  return (
    <div>
      <h1 className={styles.title}>Categories Position</h1>

      <button onClick={handleSave} className={styles.button}>
        Save
      </button>

      <div className={styles.table}>
        {positions.map((el) => (
          <div key={el.id} className={styles.row}>
            {el.photo?.path ? (
              <img src={el.photo.path} alt='Gift' className={styles['gift-photo']} />
            ) : (
              <span>N/A</span>
            )}

            <div>
              <span>{el.name}</span>
            </div>

            <div className={el.isError ? styles.inputError : styles.input}>
              <TextField
                value={el.position || ''}
                type='number'
                onChange={(e) => handleChange(e.target.value, el)}
                size='small'
                error={el.isError}
              />
              {el.isError && <span className={styles.error}>Select another position</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
