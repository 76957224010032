import React, { useEffect } from 'react';
// store
import { useDispatch } from 'react-redux';
import { getAuthToken } from './utils/authData';
import { getProfile, setAuthenticated } from './store/modules/auth';
// router
import { Switch, Redirect } from 'react-router-dom';
// pages
import { SignInPage, HomePage } from './pages';
// components
import { AuthRoute, PrivateRoute } from './components';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = getAuthToken();

    if (authToken) {
      dispatch(getProfile());
    } else {
      dispatch(setAuthenticated(false));
    }
  }, [dispatch]);

  return (
    <Switch>
      <PrivateRoute path='/admin' component={HomePage} />
      <AuthRoute path='/sign-in' component={SignInPage} />

      <Redirect to='/admin' />
    </Switch>
  );
};
