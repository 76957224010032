import React, {useEffect, useMemo} from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getGiftSales } from '../../store/modules/giftSales';
// components
import { Table } from '../../components';
// styles
import styles from '../Gifts/Gifts.module.css';
import {getGift} from "../../store/modules/gifts";

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Sale ID',
    field: 'saleId',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'External commission amount',
    field: 'externalCommissionAmount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ externalCommissionAmount }) => [Math.trunc(externalCommissionAmount * 100) / 100],
  },
  {
    title: 'Internal Commission Rate',
    field: 'internalCommissionRate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ internalCommissionRate }) => [Math.trunc(internalCommissionRate * 100) / 100],
  },
  {
    title: 'Currency',
    field: 'currency',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currency }) => [currency.code],
  },
  {
    title: 'Status',
    field: 'externalPaymentStatus',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ externalPaymentStatus }) => [externalPaymentStatus.name],
  },
  {
    title: 'Transaction date',
    field: 'transactionDate',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

export const GiftSalesDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const gift = useSelector((state) => state.gifts.details);
  const giftSales = useSelector((state) => state.giftSales);

  const additionalParams = useMemo(() => ({ giftId: match.params.id }), [match.params.id]);

  useEffect(() => {
    dispatch(getGift(match.params.id));
  }, [dispatch, match]);

  if (!gift) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>
        Gift's sales details
      </h1>
      <h2>
        #{gift.id} {gift.name}
      </h2>

      <Table
        data={giftSales}
        columns={columns}
        getData={getGiftSales}
        additionalRequestParams={additionalParams}
        defaultSort='transactionDate,DESC'
      />
    </div>
  );
};
