import React from 'react';
// formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
// styles
import styles from './SignIn.module.css';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password is too short (6 chars min)')
    .max(64, 'Password is too long (64 chars max)')
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    //   'Must contain at least one uppercase, one lowercase character and one number'
    // )
    .required('Password is required'),
});

const SignInForm = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <input
        name='email'
        type='text'
        placeholder='E-mail'
        onChange={formik.handleChange}
        value={formik.values.email}
        className={styles.input}
      />
      <input
        name='password'
        type='password'
        placeholder='Password'
        onChange={formik.handleChange}
        value={formik.values.password}
        className={styles.input}
      />
      <button type='submit' className={styles.button}>
        Sign in
      </button>
    </form>
  );
};

export default SignInForm;
