export const genderOptions = [
  {
    id: 1,
    name: 'Female',
  },
  {
    id: 2,
    name: 'Male',
  },
  {
    id: 3,
    name: 'Other',
  },
];

export const ageGroupOptions = [
  {
    id: 1,
    name: '0-18',
    from: 0,
    to: 18,
  },
  {
    id: 2,
    name: '19-25',
    from: 19,
    to: 25,
  },
  {
    id: 3,
    name: '26-40',
    from: 26,
    to: 40,
  },
  {
    id: 4,
    name: '41-100',
    from: 41,
    to: 100,
  },
];

export const dealTypeOptions = [
  {
    id: 1,
    name: 'Promo',
  },
  {
    id: 2,
    name: 'Sponsor products',
  },
];
