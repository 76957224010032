import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// store
import { useSelector } from 'react-redux';
// components
import { Header, NavBar } from '../../components';
// routes
import { routes } from './routes';
// styles
import styles from './Home.module.css';

export const HomePage = ({ match }) => {
  const adminRole = useSelector((state) => state.auth.user.role.name);

  const getRedirect = () => {
    if (adminRole === 'Gift Admin') return `${match.url}/gifts`;

    return `${match.url}/dashboard`;
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.body}>
        <NavBar />
        <main className={styles.content} id='main'>
          <Switch>
            {routes.map(({ path, component, exact = false }) => (
              <Route key={path} path={`${match.path}${path}`} component={component} exact={exact} />
            ))}
            <Redirect from='/admin' to={getRedirect()} />
          </Switch>
        </main>
      </div>
    </div>
  );
};
