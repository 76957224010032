import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import { Table } from '../../components';
// store
import { getGift, updateGift } from '../../store/modules/gifts';
// styles
import styles from './GiftPhotos.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Photo',
    field: 'path',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ path }) => <img src={path} alt='Gift' className={styles['gift-photo']} />,
  },
  {
    title: 'Url',
    field: 'path',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ path }) => path.replace(/.+\/\/|www.|\/.+/g, ''),
  },
];

const actions = ({ onDeletePhoto }) => [
  () => ({
    name: 'Delete photo',
    onClick: onDeletePhoto,
  }),
];

export const GiftPhotosPage = ({ _history, match }) => {
  const dispatch = useDispatch();
  const foundGift = useSelector((state) =>
    state.gifts.list.find((gift) => gift.id === parseFloat(match.params.id)),
  );
  const gettingGift = useSelector((state) => state.gifts.details);

  const giftData = useMemo(() => {
    if (foundGift) {
      return foundGift;
    }
    if (!foundGift && !gettingGift) {
      dispatch(getGift(match.params.id));
    }
    return gettingGift;
  }, [foundGift, gettingGift, match.params.id, dispatch]);

  const tableData = useMemo(() => giftData?.photos.sort((a, b) => a.id - b.id), [giftData]);

  const onDeletePhoto = (value) => {
    const newPhotos = giftData.photos.filter(({ id }) => id !== value.id);
    dispatch(updateGift(giftData.id, { photos: newPhotos }));
  };

  return (
    <div>
      <h1 className={styles.title}>Gift photos</h1>

      <Table
        data={{ list: tableData, isLoading: !giftData }}
        columns={columns}
        options={{ paging: false }}
        actions={actions({
          onDeletePhoto,
        })}
      />
    </div>
  );
};
