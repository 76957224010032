import React, { useCallback } from 'react';
// components
import TextField from '@material-ui/core/TextField';
// styles
import styles from './Search.module.css';

const Search = ({ onChange, value }) => {
  const handleReset = useCallback(() => onChange(''), [onChange]);

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={styles.form}>
      <div className={styles['input-wrapper']}>
        <TextField
          id='outlined-basic'
          label='Search'
          variant='outlined'
          name='search'
          value={value}
          onChange={handleChange}
          className={styles.input}
          size='small'
          autoComplete='off'
        />

        <span className={styles.reset} onClick={handleReset} />
      </div>
    </div>
  );
};

export default Search;
