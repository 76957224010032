import React from 'react';
// router
import { useHistory } from 'react-router-dom';
// formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import ImageUploader from 'react-images-upload';
import { Input, Textarea, Select, RadioButton } from '../../components';
// styles
import styles from './GiftCreate.module.css';

const statusOptions = [
  {
    id: 1,
    name: 'Active',
  },
  {
    id: 2,
    name: 'Reserved',
  },
];

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.string().required('Price is required'),
  link: Yup.string().url('Invalid URL').required('Link is required'),
  status: Yup.object().test('status', 'Status is required', (value) => value.id),
  category: Yup.object().test('category', 'Category is required', (value) => value.id),
  currency: Yup.object().test('currency', 'Currency is required', (value) => value.id),
});

export const GiftCreateForm = ({ categories, onSubmit, currency, countries }) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: '',
      price: '',
      link: '',
      description: '',
      category: {},
      status: {},
      isHidden: false,
      photos: [],
      currency: {},
      countries: [],
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <ImageUploader
        withIcon={false}
        withLabel={false}
        withPreview
        imgExtension={['.jpg', '.png', '.jpeg']}
        maxFileSize={5242880}
        onChange={(images) => formik.setFieldValue('photos', images)}
        name='photos'
      />
      <Input
        label='Name'
        name='name'
        placeholder='Name'
        onChange={formik.handleChange}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        isRequired
      />
      <Input
        label='Price'
        name='price'
        placeholder='Price'
        type='number'
        step='0.01'
        onChange={formik.handleChange}
        value={formik.values.price}
        onBlur={formik.handleBlur}
        error={formik.touched.price && formik.errors.price}
        isRequired
      />
      <Input
        label='Link to store'
        name='link'
        placeholder='Link to store'
        onChange={formik.handleChange}
        value={formik.values.link}
        onBlur={formik.handleBlur}
        error={formik.touched.link && formik.errors.link}
        isRequired
      />
      <Textarea
        label='Description'
        name='description'
        placeholder='Description'
        onChange={formik.handleChange}
        value={formik.values.description}
      />
      <Select
        label='Category'
        name='category'
        options={categories.list}
        onChange={(value) => formik.setFieldValue('category', value)}
        onBlur={formik.handleBlur}
        error={formik.touched.category && formik.errors.category}
        isRequired
      />

      <Select
        label='Currecny'
        name='currency'
        options={Object.values(currency)}
        onChange={(value) => formik.setFieldValue('currency', value)}
        onBlur={formik.handleBlur}
        error={formik.touched.currency && formik.errors.currency}
        isRequired
      />

      <Select
        isMulti
        label='Countries'
        name='countries'
        options={countries}
        value={formik.values.countries}
        onChange={(value) => formik.setFieldValue('countries', value)}
      />

      <div className={styles['radio-group']}>
        <label className={styles.label}>Gift status</label>

        <div className={styles['radio-wrapper']}>
          {statusOptions.map((option) => (
            <RadioButton
              key={option.id}
              label={option.name}
              name='status'
              value={option}
              checked={formik.values.status?.name === option.name}
              onChange={() => formik.setFieldValue('status', option)}
            />
          ))}
        </div>
        {formik.touched.status && formik.errors.status && (
          <p className={styles.error}>{formik.errors.status}</p>
        )}
      </div>

      <button type='button' className={styles['button-back']} onClick={() => history.goBack()}>
        Back
      </button>
      <button type='submit' className={styles['button-submit']}>
        Save
      </button>
    </form>
  );
};
