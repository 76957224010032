import React, { useEffect, useRef, useState, useMemo } from 'react';
// components
import Dropdown, { DropdownToggle, DropdownMenu, MenuItem } from '@trendmicro/react-dropdown';
import MaterialTable from 'material-table';
// styles
import styles from './Table.module.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

export const StaticTable = ({
  staticData,
  options,
  columns,
  actions,
  customRef,
  pageSize = 25,
  ...rest
}) => {
  const tableRef = useRef(null);
  const [initialPageSize, setInitialPageSize] = useState(pageSize);

  useEffect(() => {
    if (customRef) {
      customRef.current = {
        pageSize: tableRef.current.state.pageSize,
      };
    }
  }, [customRef]);

  const isPaging = useMemo(() => staticData.length > initialPageSize, [
    staticData,
    initialPageSize,
  ]);

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={columns}
      options={{
        pageSize: initialPageSize,
        pageSizeOptions: [10, 25, 50],
        actionsColumnIndex: -1,
        exportAllData: true,
        exportButton: true,
        showTitle: false,
        draggable: false,
        headerStyle: {
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        },
        rowStyle: {
          fontSize: 14,
          maxWidth: 500,
        },
        paging: isPaging,
        ...options,
      }}
      data={staticData}
      components={{
        Container: (props) => <div className={styles['table-container']}>{props.children}</div>,
        Actions: (props) =>
          !!props.actions.length && (
            <Dropdown pullRight onClick={(event) => event.stopPropagation()}>
              <DropdownToggle btnStyle='flat'>Open</DropdownToggle>
              <DropdownMenu>
                {props.actions.map(({ action }, i) => {
                  const { onClick, name, isDisabled } = action(props.data);

                  return (
                    <MenuItem
                      key={i}
                      eventKey={i + 1}
                      onSelect={() => onClick(props.data)}
                      disabled={isDisabled}
                    >
                      {name}
                    </MenuItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          ),
      }}
      onChangeRowsPerPage={setInitialPageSize}
      actions={actions}
      {...rest}
    />
  );
};
