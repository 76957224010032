import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, RadioButton } from '../../components';
import styles from './AdminDetails.module.css';

const roleOptions = [
  { id: 2, name: 'Admin' },
  { id: 3, name: 'Gift Admin' },
  { id: 4, name: 'Parser Admin' },
];

const initialValues = {
  email: '',
  password: '',
  role: {},
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password is too short (6 chars min)')
    .max(64, 'Password is too long (64 chars max)'),
  role: Yup.object({
    id: Yup.number().required(),
    name: Yup.string().required(),
  }),
});

export const AdminDetailsForm = ({ values, onSubmit, isCreate }) => {
  const [isChangePassword, setChangePassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values ? { ...values, password: '' } : initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      {!isCreate && (
        <Input
          label='ID'
          name='id'
          placeholder='ID'
          onChange={formik.handleChange}
          value={formik.values.id}
          disabled
        />
      )}
      {/* Prevent auto fill input */}
      <input
        type='text'
        name='email'
        autoComplete='email'
        readOnly={true}
        style={{ display: 'none' }}
      />
      {/* *********************** */}
      <Input
        label='Email'
        name='email'
        placeholder='Email'
        onChange={formik.handleChange}
        value={formik.values.email}
        disabled={!isCreate}
        autoComplete='false'
      />
      <Input
        label='Password'
        name='password'
        type='password'
        placeholder='Password'
        onChange={formik.handleChange}
        value={formik.values.password}
        disabled={!isCreate && !isChangePassword}
        autoComplete='whatever'
      />
      {!isCreate && (
        <button type='button' onClick={() => setChangePassword(true)} className={styles.button}>
          Change password
        </button>
      )}

      <div className={styles['radio-wrapper']}>
        <label className={styles.label}>Role</label>
        {roleOptions.map((option) => (
          <RadioButton
            key={option.id}
            name='role'
            value={option}
            label={option.name}
            checked={formik.values.role?.name === option.name}
            onChange={() => formik.setFieldValue('role', option)}
          />
        ))}
      </div>

      <button type='submit' className={styles['button-submit']}>
        Save
      </button>
    </form>
  );
};
