import React from 'react';
import { FieldArray } from 'formik';
import styles from './ParserRulesDetails.module.css';

export const FieldGroup = ({
  name,
  title,
  values,
  handleChange,
  initialValue,
  setFieldValue,
  component: Component,
}) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <div className={styles.fieldArray}>
        <div className={styles.fieldTitle}>{title}</div>

        <div className={styles.fieldArrayBodyWrapper}>
          {values.map((value, index) => (
            <Component
              key={index}
              index={index}
              value={value}
              name={name}
              handleChange={handleChange}
              handleRemove={arrayHelpers.remove}
              setFieldValue={setFieldValue}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <button
            type='button'
            className={styles.buttonAdd}
            onClick={() => arrayHelpers.push(initialValue)}
          >
            +
          </button>
        </div>
      </div>
    )}
  />
);
