import React from 'react';
import styles from './Form.module.css';

export const Input = ({ label, error, isRequired, type = 'text', ...rest }) => (
  <label className={styles.label}>
    <span className={isRequired && styles.labelName}>{label}</span>
    <input type={type} className={styles.input} {...rest} />
    {error && <p className={styles.error}>{error}</p>}
  </label>
);
