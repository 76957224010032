import React, { useEffect } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { uploadPhoto } from '../../store/modules/gifts';
import { createDeal, getCountries } from '../../store/modules/deals';
// components
import { DealsDetailsForm } from '../DealsDetails/DealsDetailsForm';
// styles
import styles from './DealsCreate.module.css';

export const DealsCreatePage = ({ history }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.deals.countries);

  const onCreateDeal = ({ photo, photoPreview, ...values }) => {
    const expirationDate = values.expirationDate || '2050-01-01T10:00:00.000+03:00';

    if (!photo || photo?.path) {
      dispatch(createDeal({ ...values, expirationDate })).then(() => {
        history.goBack();
      });
      return;
    }

    const formData = new FormData();
    const file = new File([photo], 'image.jpeg');

    formData.append('file', file);

    dispatch(uploadPhoto(formData)).then((response) => {
      dispatch(
        createDeal({
          ...values,
          photo: response.value,
          expirationDate,
        }),
      ).then(() => {
        history.goBack();
      });
    });
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div>
      <h1 className={styles.title}>Create deal</h1>

      <DealsDetailsForm onSubmit={onCreateDeal} countries={countries} />
    </div>
  );
};
