// library
import { library } from '@fortawesome/fontawesome-svg-core';
// icons
import {
  faGift,
  faTachometerAlt,
  faUsers,
  faListAlt,
  faEyeDropper,
  faSave,
  faBolt,
  faBell,
  faWrench,
  faUser,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBell,
  faBolt,
  faSave,
  faEyeDropper,
  faListAlt,
  faGift,
  faTachometerAlt,
  faUsers,
  faWrench,
  faUser,
  faArrowCircleRight,
);
