import React, { useCallback, useRef } from 'react';
// store
import { useSelector } from 'react-redux';
import { getUserDeals } from '../../store/modules/userDeals';
// components
import { Table } from '../../components';
// styles
import styles from './UserDeals.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    width: 100,
  },
  {
    title: 'Photo',
    field: 'photo',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', maxWidth: '200px' },
    sorting: false,
    render: ({ photo }) => {
      return photo ? <img src={photo.path} alt='Gift' className={styles['gift-photo']} /> : 'N/A';
    },
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    width: '60%',
  },
  {
    title: 'Type',
    field: 'dealType.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Start Date',
    field: 'startDate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Expire time',
    field: 'expirationDate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
];

export const UserDealsPage = ({ match, history }) => {
  const tableRef = useRef(null);

  const userDeals = useSelector((state) => state.userDeals);

  const handlerSearchParams = useCallback(
    ({ params }) => ({
      $and: [
        { '"UserDeal"."userId"': match.params.id },
        {
          '"UserDeal"."deal"."startDate"': { $lte: new Date().toISOString() },
        },
        {
          '"UserDeal"."deal"."expirationDate"': {
            $gte: new Date().toISOString(),
          },
        },
        params,
      ],
    }),
    [match],
  );

  return (
    <div>
      <h1>User Deals</h1>

      <Table
        customRef={tableRef}
        data={userDeals}
        getData={getUserDeals}
        columns={columns}
        searchParams={handlerSearchParams}
        defaultSort='createdAt,DESC'
      />
    </div>
  );
};
