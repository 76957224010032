import React, { useState, useRef, useCallback } from 'react';
// store
import { getParserFails, updateParserFail } from '../../store/modules/parserFails';
import { useDispatch, useSelector } from 'react-redux';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './ParserFails.module.css';

const columns = [
  {
    title: 'URL',
    field: 'url',
    emptyValue: 'N/A',
    cellStyle: { minWidth: 800 },
    render: ({ url }) => (
      <a href={url} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>
        {url}
      </a>
    ),
  },
  {
    title: 'Is timeout?',
    field: 'isTimeout',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Title',
    field: 'title',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Description',
    field: 'description',
    emptyValue: 'N/A',
    cellStyle: {
      maxWidth: '400px',
      overflowWrap: 'break-word',
    },
  },
  {
    title: 'Images',
    field: 'images',
    emptyValue: 'N/A',
    cellStyle: {
      maxWidth: '400px',
      overflowWrap: 'break-word',
    },
  },
  {
    title: 'Created time',
    field: 'createdAt',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Price',
    field: 'price',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Currency',
    field: 'currency',
    emptyValue: 'N/A',
    whiteSpace: 'wrap',
    cellStyle: {
      maxWidth: '200px',
      overflowWrap: 'break-word',
    },
  },
  {
    title: 'Error Message',
    field: 'errorMessage',
    emptyValue: 'N/A',
    whiteSpace: 'wrap',
    cellStyle: {
      maxWidth: '200px',
      overflowWrap: 'break-word',
    },
  },
];

const actions = ({ onResolveParser }) => [
  () => ({
    name: 'Resolve',
    onClick: onResolveParser,
  }),
];

export const ParserFailsPage = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();

  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedParserFails, setSelectedParserFails] = useState([]);
  const parserFails = useSelector((state) => state.parserFails);

  const onResolveParser = (rowData, fetchData) => {
    dispatch(updateParserFail(rowData.id, { isResolved: true })).then(() =>
      fetchData(
        parserFails.count === 1 ? parserFails.page - 1 : parserFails.page,
        tableRef.current.pageSize,
      ),
    );
  };

  const onResolveAllFails = () => {
    const resolvesPromise = selectedParserFails.map((parserFail) => {
      return dispatch(updateParserFail(parserFail, { isResolved: true }));
    });

    Promise.all(resolvesPromise).then(() => {
      tableRef.current.fetchData(parserFails.page, tableRef.current.pageSize);
    });
  };

  const onSelectAll = () => {
    const selectedAll = parserFails.list.map((parserFail) => parserFail.id);

    setSelectedParserFails([...selectedParserFails, ...selectedAll]);
  };

  const onDeselectAll = () => {
    setSelectedParserFails([]);
  };

  const onRowClick = (event, rowData) => {
    const giftIDs = selectedParserFails.includes(rowData.id)
      ? selectedParserFails.filter((gift) => gift !== rowData.id)
      : [...selectedParserFails, rowData.id];

    setSelectedParserFails(giftIDs);
  };

  const handlerSearchParams = useCallback(
    ({ params, search }) => ({
      $and: [
        { isResolved: { $or: { $eq: false, $isnull: true } } },
        !search && {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        !search && {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        params,
      ],
    }),
    [startDate, endDate],
  );

  return (
    <div>
      <h1 className={styles.title}>Parser fails</h1>

      <div className={styles.action}>
        <div className={styles.buttons}>
          <button
            type='button'
            onClick={onResolveAllFails}
            className={`${styles.button} ${styles.resolve}`}
            disabled={!selectedParserFails.length}
          >
            Resolve
          </button>
          <button type='button' className={styles.button} onClick={onSelectAll}>
            Select all
          </button>
          <button
            type='button'
            className={styles.button}
            onClick={onDeselectAll}
            disabled={!selectedParserFails.length}
          >
            Deselect all
          </button>
        </div>

        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      <Table
        customRef={tableRef}
        data={parserFails}
        getData={getParserFails}
        columns={columns}
        actions={actions({ onResolveParser })}
        onRowClick={onRowClick}
        defaultSort='createdAt,DESC'
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: selectedParserFails.includes(rowData.id) && 'rgba(0, 0, 0, 0.08)',
            fontSize: 14,
          }),
        }}
        searchParams={handlerSearchParams}
      />
    </div>
  );
};
