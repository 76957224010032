import React from 'react';
// store
import { useDispatch } from 'react-redux';
import { createUser } from '../../store/modules/users';
// components
import { AdminDetailsForm } from '../AdminDetails/AdminDetailsForm';
// styles
import styles from './AdminCreate.module.css';

export const AdminCreatePage = ({ history }) => {
  const dispatch = useDispatch();

  const onCreateAdmin = (values) => {
    const splitedRole = values.role.name.split(' ');

    dispatch(
      createUser({
        ...values,
        status: { id: 1 },
        firstName: splitedRole[0],
        lastName: splitedRole[1] || 'Basic',
      }),
    ).then(() => history.goBack());
  };

  return (
    <div>
      <h1 className={styles.title}>Add new administrator</h1>

      <AdminDetailsForm onSubmit={onCreateAdmin} isCreate />
    </div>
  );
};
