import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
// material-ui components
import { Button } from '@material-ui/core';
// store
import { useDispatch } from 'react-redux';
// components
import Dropdown, { DropdownToggle, DropdownMenu, MenuItem } from '@trendmicro/react-dropdown';
import MaterialTable from 'material-table';
import Search from './Search';
// utils
import { checkNumberValue } from '../../utils';
// styles
import styles from './Table.module.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const getSearchParams = (columns, value) => {
  const fieldsNotSearch = [
    'follow',
    'photos',
    'isTimeout',
    'photo',
    'position',
    'user.accountTypeId',
    'currency',
  ]; // TODO: wait fix followersCount and followingCount for search from backend
  const filteredColumns = columns.filter(
    (column) => !column.type?.includes('date') && !fieldsNotSearch.includes(column.field),
  );

  return filteredColumns.map((column) => {
    return {
      [column.field]: column.type === 'numeric' ? checkNumberValue(value) : { $contL: value },
    };
  });
};

export const Table = ({
  data,
  options,
  columns,
  actions,
  getData,
  searchParams,
  additionalRequestParams = undefined,
  customRef,
  defaultSort,
  defaultPageSize = 25,
  advancedGetSearchParams,
  isLoadMore,
  loadMoreData,
  ...rest
}) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState(undefined);
  const [initialPageSize, setInitialPageSize] = useState(defaultPageSize);
  const [defaultPage, setDefaultPage] = useState(1);
  const scrollTopMain = useRef(null);

  const debouncedSearch = useDebounce(searchValue, 800);

  const fetchData = useCallback(
    (page = 1, limit = initialPageSize, useLoadMore = false) => {
      const sParams = advancedGetSearchParams
        ? advancedGetSearchParams(columns, debouncedSearch)
        : getSearchParams(columns, debouncedSearch);

      const s = searchParams
        ? searchParams({
            params: { $or: debouncedSearch ? sParams : {} },
            search: debouncedSearch,
          })
        : { $or: sParams };

      if (useLoadMore) {
        dispatch(
          loadMoreData({
            limit,
            page,
            s,
            sort: sortValue ? sortValue : defaultSort,
            ...additionalRequestParams,
          }),
        ).then(() => tableRef.current && tableRef.current.onQueryChange());
      } else {
        dispatch(
          getData({
            limit,
            page,
            s,
            sort: sortValue ? sortValue : defaultSort,
            ...additionalRequestParams,
          }),
        ).then(() => tableRef.current && tableRef.current.onQueryChange());
      }
    },
    [
      sortValue,
      debouncedSearch,
      columns,
      getData,
      dispatch,
      searchParams,
      initialPageSize,
      defaultSort,
      advancedGetSearchParams,
      loadMoreData,
      additionalRequestParams,
    ],
  );

  const scrollMainToTop = useCallback(() => {
    const matTable = document.getElementById('main');
    matTable.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (customRef) {
      customRef.current = {
        pageSize: tableRef.current.state.pageSize,
        fetchData,
      };
    }

    getData && fetchData();
  }, [fetchData, getData, customRef]);

  useEffect(() => {
    if (!isLoadMore) {
      scrollMainToTop();
    }
    tableRef.current && tableRef.current.onQueryChange();
  }, [data.list, scrollMainToTop, isLoadMore]);

  const isPaging = useMemo(() => data.total > initialPageSize, [data, initialPageSize]);

  const handleLoadMore = useCallback(async () => {
    scrollTopMain.current = document.getElementById('main').scrollTop;
    fetchData(defaultPage + 1, initialPageSize, true);
    setDefaultPage((prevState) => prevState + 1);
  }, [defaultPage, fetchData, initialPageSize]);

  return (
    <div>
      <Search onChange={setSearchValue} value={searchValue} />

      <MaterialTable
        isLoading={data.isLoading}
        tableRef={tableRef}
        columns={columns}
        data={(query) =>
          new Promise((resolve) => {
            setSortValue(
              query.orderBy && query.orderDirection
                ? `${query.orderBy.field},${query.orderDirection.toUpperCase()}`
                : undefined,
            );

            resolve({
              data: data.list,
              page: data.page - 1,
              totalCount: data.total || data.list.length,
            });

            if (isLoadMore && data.list.length > defaultPageSize) {
              document.getElementById('main').scrollTop = scrollTopMain.current ?? 0;
            }
          })
        }
        options={{
          pageSize: initialPageSize,
          pageSizeOptions: [10, 25, 50],
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          showTitle: false,
          draggable: false,
          search: false,
          headerStyle: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          },
          rowStyle: {
            fontSize: 14,
            maxWidth: 500,
          },
          paging: isPaging,
          ...options,
        }}
        components={{
          Container: (props) => <div className={styles['table-container']}>{props.children}</div>,
          Actions: (props) =>
            !!props.actions.length && (
              <Dropdown pullRight onClick={(event) => event.stopPropagation()}>
                <DropdownToggle btnStyle='flat'>Open</DropdownToggle>
                <DropdownMenu>
                  {props.actions.map(({ action }, i) => {
                    const { onClick, name, isDisabled } = action(props.data);

                    return (
                      <MenuItem
                        key={i}
                        eventKey={i + 1}
                        onSelect={() => onClick(props.data, fetchData)}
                        disabled={isDisabled}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            ),
        }}
        onChangeRowsPerPage={setInitialPageSize}
        actions={actions}
        onChangePage={(page, pageSize) => fetchData(page + 1, pageSize)}
        {...rest}
      />
      {isLoadMore && (
        <div className={styles.loadMoreButtonContainer}>
          <Button
            variant='outlined'
            disabled={data.isLoading || data.stopLoadMore}
            onClick={handleLoadMore}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};
