import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_REPORTS = 'reports/GET_REPORTS';

// action creators
export const getReports = createAction(GET_REPORTS, api.reports.getReports);

// reducer
const reportsReducer = handleActions(
  {
    [`${GET_REPORTS}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_REPORTS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      list: data,
      isLoading: false,
      ...rest,
    }),

    [`${GET_REPORTS}_ERROR`]: () => initialState,
  },
  initialState,
);

export default reportsReducer;
