import React, { useCallback } from 'react';
// store
import { useSelector } from 'react-redux';
import { getReports } from '../../store/modules/reports';
// components
import { Table } from '../../components';
// styles
import styles from './Reports.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    width: 100,
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    width: '60%',
  },
  {
    title: 'Gifts',
    field: 'giftCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Users',
    field: 'userCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
];

export const ReportsPage = () => {
  const reports = useSelector((state) => state.reports);

  const handlerSearchParams = useCallback(({ params }) => ({ $and: [params] }), []);

  return (
    <div>
      <h1 className={styles.title}>Reports</h1>

      <Table
        data={reports}
        getData={getReports}
        columns={columns}
        searchParams={handlerSearchParams}
      />
    </div>
  );
};
