import React, { useCallback } from 'react';
// store
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, updateUser } from '../../store/modules/users';
// components
import { Table } from '../../components';
// styles
import styles from './SystemSettings.module.css';

const columns = [
  {
    title: 'Email',
    field: 'email',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Role',
    field: 'role.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Enabled',
    field: 'status.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onAdminDetails, onAdminEnabled }) => [
  () => ({
    name: 'Details',
    onClick: onAdminDetails,
  }),
  (admin) => ({
    name: admin.status.name === 'Inactive' ? 'Enable' : 'Disable',
    onClick: onAdminEnabled,
  }),
];

export const SystemSettingsPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const admins = useSelector((state) => state.users);

  const onAdminDetails = (rowData) => history.push(`${match.url}/administrator/${rowData.id}`);

  const onAdminEnabled = (rowData) =>
    dispatch(
      updateUser(rowData.id, {
        status: rowData.status.name === 'Inactive' ? { id: 1 } : { id: 2 },
      }),
    );

  const onAdminCreate = () => history.push(`${match.url}/administrator/create`);

  const handlerSearchParams = useCallback(
    ({ params }) => ({
      $and: [{ $or: [{ 'role.id': 2 }, { 'role.id': 3 }, { 'role.id': 4 }] }, params],
    }),
    [],
  );

  return (
    <div>
      <h1 className={styles.title}>Administrators</h1>

      <button type='button' onClick={onAdminCreate} className={styles.button}>
        Add new administrator
      </button>

      <Table
        data={admins}
        getData={getUsers}
        columns={columns}
        actions={actions({ onAdminDetails, onAdminEnabled })}
        searchParams={handlerSearchParams}
      />
    </div>
  );
};
