import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  details: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
  topGifts: {
    list: [],
    isLoading: false,
    stopLoadMore: false,
  },
};

// types
export const GET_GIFTS = 'gifts/GET_GIFTS';
export const GET_GIFT = 'gifts/GET_GIFT';
export const UPDATE_GIFT = 'gifts/UPDATE_GIFT';
export const CREATE_GIFT = 'gifts/CREATE_GIFT';
export const UPLOAD_PHOTO = 'gifts/UPLOAD_PHOTO';
export const REMOVE_GIFT_CATEGORY = 'gifts/REMOVE_GIFT_CATEGORY';
export const GET_TOP_GIFTS = 'gifts/GET_TOP_GIFTS';
export const LOAD_MORE_TOP_GIFTS = 'gifts/LOAD_MORE_TOP_GIFTS';

// action creators
export const getGifts = createAction(GET_GIFTS, api.gifts.getGifts);
export const getGift = createAction(GET_GIFT, api.gifts.getGift);
export const updateGift = createAction(UPDATE_GIFT, api.gifts.updateGift);
export const createGift = createAction(CREATE_GIFT, api.gifts.createGift);
export const uploadPhoto = createAction(UPLOAD_PHOTO, api.files.uploadFile);
export const removeGiftCategory = createAction(REMOVE_GIFT_CATEGORY, api.gifts.updateGift);
export const getTopGifts = createAction(GET_TOP_GIFTS, api.gifts.getTopGifts);
export const loadMoreTopGifts = createAction(LOAD_MORE_TOP_GIFTS, api.gifts.getTopGifts);

// reducer
const giftsReducer = handleActions(
  {
    [combineActions(
      `${GET_GIFTS}_REQUEST`,
      `${UPDATE_GIFT}_REQUEST`,
      `${REMOVE_GIFT_CATEGORY}_REQUEST`,
    )]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_GIFT}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      details: payload,
      isLoading: false,
    }),

    [`${GET_TOP_GIFTS}_REQUEST`]: (state) => ({
      ...state,
      topGifts: {
        list: state.topGifts.list,
        isLoading: true,
      },
    }),

    [`${GET_TOP_GIFTS}_SUCCESS`]: (state, { payload: { giftsRepeated } }) => ({
      ...state,
      topGifts: {
        list: giftsRepeated.map(({ countRepeatedGift, gift }) => ({
          ...gift,
          countRepeatedGift,
        })),
        isLoading: false,
        stopLoadMore: giftsRepeated.length < 50,
      },
    }),

    [`${LOAD_MORE_TOP_GIFTS}_REQUEST`]: (state) => ({
      ...state,
      topGifts: {
        list: state.topGifts.list,
        isLoading: true,
      },
    }),

    [`${LOAD_MORE_TOP_GIFTS}_SUCCESS`]: (state, { payload: { giftsRepeated } }) => ({
      ...state,
      topGifts: {
        list: [
          ...state.topGifts.list,
          ...giftsRepeated.map(({ countRepeatedGift, gift }) => ({
            ...gift,
            countRepeatedGift,
          })),
        ],
        isLoading: false,
        stopLoadMore: giftsRepeated.length < 50,
      },
    }),

    [`${GET_GIFTS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),

    [`${UPDATE_GIFT}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      details: payload,
      list: state.list.map((item) => (item.id === payload.id ? payload : item)),
      isLoading: false,
    }),

    [`${REMOVE_GIFT_CATEGORY}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      list: state.list.filter((item) => item.id !== payload.id),
      isLoading: false,
    }),

    [combineActions(`${GET_GIFTS}_ERROR`, `${UPDATE_GIFT}_ERROR`, `${GET_GIFT}_ERROR`)]: () =>
      initialState,
  },
  initialState,
);

export default giftsReducer;
