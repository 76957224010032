import React from 'react';
import ReactSelect from 'react-select';
import styles from './Form.module.css';

const getOptions = (options) =>
  options &&
  options.map((option) => ({
    value: option?.name?.toLowerCase() || option.code,
    label: option?.name || option?.code,
    ...option,
  }));

const getOption = (option) =>
  option && {
    value: option?.name?.toLowerCase() || option.code,
    label: option?.name || option?.code,
    ...option,
  };

const customStyles = {
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 14,
    color: '#555',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#66afe9' : '#ccc',
    boxShadow: state.isFocused
      ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)'
      : 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 11,
  }),
};

export const Select = ({ label, value, options, isRequired, error, isMulti, ...rest }) => (
  <label className={styles.label}>
    <span className={isRequired && styles.labelName}>{label}</span>
    <ReactSelect
      options={getOptions(options)}
      value={isMulti ? getOptions(value) : getOption(value)}
      className={styles.select}
      isMulti={isMulti}
      styles={customStyles}
      {...rest}
    />
    {error && <p className={styles.error}>{error}</p>}
  </label>
);
