import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const AuthRoute = ({ component: Component, ...props }) => {
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  return (
    <Route
      {...props}
      render={(route) => (isAuthenticated ? <Redirect to='/admin' /> : <Component {...route} />)}
    />
  );
};
