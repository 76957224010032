import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_USER_DEALS = 'usersDeals/GET_USER_DEALS';
export const DELETE_USER_DEAL = 'usersDeals/DELETE_USER_DEAL';

// action creators
export const getUserDeals = createAction(GET_USER_DEALS, api.userDeals.getList);

const userDealsReducer = handleActions(
  {
    [`${GET_USER_DEALS}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_USER_DEALS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data.map((el) => el.deal),
      isLoading: false,
    }),

    [combineActions(`${GET_USER_DEALS}_ERROR`)]: () => initialState,
  },
  initialState,
);

export default userDealsReducer;
