import React, { useState, useCallback, useMemo } from 'react';
import { VictoryLabel, VictoryPie, VictoryTheme } from 'victory';
// styles
import styles from './Dashboard.module.css';

const SORT = {
  name: 'x',
  count: 'y',
};

export const PieChartLegend = ({ data, legendData, ...props }) => {
  const [sort, setSort] = useState({ field: SORT.count, desc: true });

  const sorting = useCallback(
    (_data) =>
      _data.sort((a, b) =>
        sort.desc
          ? typeof b[sort.field] === 'number'
            ? b[sort.field] - a[sort.field]
            : b[sort.field].toString().localeCompare(a[sort.field])
          : typeof a[sort.field] === 'number'
          ? a[sort.field] - b[sort.field]
          : a[sort.field].toString().localeCompare(b[sort.field]),
      ),
    [sort],
  );

  const getList = useCallback(
    (_data) =>
      _data
        .filter((item) => item.userCount)
        .map((item) => ({
          x: item.name || 'Unknown',
          y: item.userCount,
        })),
    [],
  );

  const handleClickCountry = useCallback(
    () => setSort((prev) => ({ field: 'x', desc: !prev.desc })),
    [],
  );

  const handleClickCount = useCallback(
    () => setSort((prev) => ({ field: 'y', desc: !prev.desc })),
    [],
  );

  const sortedLegendData = useMemo(() => {
    if (legendData) {
      return sorting(getList(legendData));
    }
    return sorting(getList(data));
  }, [data, legendData, getList, sorting]);

  return (
    <>
      <div className={styles.legend}>
        <div className={styles.legendHeader}>
          <span onClick={handleClickCountry} className={styles.headerItem}>
            Country
          </span>
          <span onClick={handleClickCount} className={styles.headerItem}>
            Amount
          </span>
        </div>
        <div className={styles.legendBody}>
          {sortedLegendData.map((el) => (
            <p key={el.x} className={styles.legendRow}>
              <span>{el.x}</span>
              <span>{el.y}</span>
            </p>
          ))}
        </div>
      </div>

      <VictoryPie
        data={getList(data)}
        labels={({ datum, data }) => {
          const total = data.reduce((sum, item) => sum + item.y, 0);
          const percentage = Math.round((datum.y / total) * 100);
          return [datum.x, ` ${percentage}%`];
        }}
        labelPosition='centroid'
        labelPlacement='parallel'
        theme={VictoryTheme.material}
        labelComponent={
          <VictoryLabel
            inline
            style={[
              {
                fontFamily: "'Helvetica Neue', Helvetica, 'Roboto', sans-serif",
              },
              {
                fontSize: 12,
                fontWeight: 'bold',
                fontFamily: "'Helvetica Neue', Helvetica, 'Roboto', sans-serif",
              },
            ]}
          />
        }
        style={{
          parent: { width: 500, height: 500, margin: '100px 0 0 100px' },
        }}
        {...props}
      />
    </>
  );
};
