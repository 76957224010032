import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_STORES = 'stores/GET_STORES';

// action creators
export const getStores = createAction(GET_STORES, api.stores.getStores);

// reducer
const storesReducer = handleActions(
  {
    [`${GET_STORES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_STORES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      list: data,
      isLoading: false,
      ...rest,
    }),

    [`${GET_STORES}_ERROR`]: () => initialState,
  },
  initialState,
);

export default storesReducer;
