import React, { useEffect, useCallback, useMemo } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser } from '../../store/modules/users';
// components
import { UserDetailsForm } from './UserDetailsForm';
// styles
import styles from './UserDetails.module.css';

const ROLES = {
  user: {
    id: 1,
    name: 'User',
  },
  influencer: {
    id: 5,
    name: 'Influencer',
  },
};

export const UserDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);

  const onUpdateUser = useCallback(
    ({ email, isInfluencer, ...values }) => {
      const role = isInfluencer ? ROLES.influencer : ROLES.user;
      const formData = { ...values, role, ...(email !== user.email && { email }) };
      try {
        dispatch(updateUser(user.id, formData)).then(() => history.goBack());
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch, user, history],
  );

  useEffect(() => {
    dispatch(getUser(match.params.id));
  }, [dispatch, match]);

  const userData = useMemo(
    () => ({ ...user, isInfluencer: user?.role?.name === ROLES.influencer.name }),
    [user],
  );

  if (!user) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>
        #{user.id} {user.firstName} {user.lastName}
      </h1>
      <UserDetailsForm values={userData} onSubmit={onUpdateUser} />
    </div>
  );
};
