import React from 'react';
// components
import DatePicker from 'react-datepicker';
// styles
import styles from './DatePicker.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickers = ({ startDate, endDate, setStartDate, setEndDate }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <span className={styles.dateTitle}>Start date: </span>

      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        disabledKeyboardNavigation
        showYearDropdown
      />
    </div>

    <div className={styles.container}>
      <span className={styles.dateTitle}>End date: </span>

      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        disabledKeyboardNavigation
        showYearDropdown
      />
    </div>
  </div>
);

export default DatePickers;
