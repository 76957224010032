import React from 'react';
import { Input, Checkbox } from '../../components';
import styles from './ParserRulesDetails.module.css';

export const ParserRulesFields = ({
  index,
  value,
  name,
  handleChange,
  handleRemove,
  setFieldValue,
}) => (
  <div className={styles.fieldArrayBody}>
    <div className={styles.fieldArrayBodyTitleWrapper}>
      <div className={styles.fieldArrayBodyTitle}>Rule #{index + 1}</div>
      <button type='button' onClick={() => handleRemove(index)} className={styles.buttonRemove}>
        x
      </button>
    </div>
    <Input
      name={`${name}[${index}].selector`}
      label='Selector'
      placeholder='selector'
      onChange={handleChange}
      value={value.selector}
    />
    <Checkbox
      label='HTML'
      name={`${name}[${index}].select_html`}
      value={value.select_html}
      checked={value.select_html}
      onChange={() => setFieldValue(`${name}[${index}].select_html`, !value.select_html)}
      className={styles.checkbox}
    />
    <Input
      name={`${name}[${index}].attribute`}
      label='Attribute'
      placeholder='attribute'
      onChange={handleChange}
      value={value?.attribute}
    />
    <div className={styles.fields}>
      <label className={styles.label}>Regexp</label>
      <Input
        name={`${name}[${index}].regexp.pattern`}
        placeholder='pattern'
        onChange={handleChange}
        value={value?.regexp?.pattern}
      />
      <Input
        name={`${name}[${index}].regexp.replacement`}
        placeholder='replacement'
        onChange={handleChange}
        value={value?.regexp?.replacement}
      />
    </div>
    <Input
      name={`${name}[${index}].json_path`}
      label='Json path'
      placeholder='json path'
      onChange={handleChange}
      value={value.json_path}
    />
  </div>
);
