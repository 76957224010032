import { createStore, applyMiddleware, compose } from 'redux';
import { createPromise } from 'redux-promise-middleware';
import rootReducer from './rootReducer';

const initialState = {};

const promise = createPromise({
  promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'],
});

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(applyMiddleware(promise));

const store = createStore(rootReducer, initialState, enhancer);

export default store;
