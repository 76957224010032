export const setAuthToken = (data) => {
  if (data) {
    localStorage['accessToken'] = data;
  }
};

export const getAuthToken = () => {
  return localStorage['accessToken'];
};

export const clearAuthToken = () => {
  delete localStorage['accessToken'];
};
