import React from 'react';
// store
import { useDispatch } from 'react-redux';
import { setAuthToken } from '../../utils/authData';
import { signInRequest } from '../../store/modules/auth';
// components
import SignInForm from './SignInForm';
// styles
import styles from './SignIn.module.css';

export const SignInPage = () => {
  const dispatch = useDispatch();

  const onSignIn = (values) => {
    dispatch(signInRequest(values))
      .then(({ value }) => setAuthToken(value.token))
      .catch((error) => console.log('error request', error));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.panel}>
        <header className={styles.header}>Administration Panel</header>

        <SignInForm onSubmit={onSignIn} />
      </div>
    </div>
  );
};
