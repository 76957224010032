import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_USERS_SALES = 'usersSales/GET_USERS_SALES';

// action creators
export const getUsersSales = createAction(GET_USERS_SALES, api.usersSales.getUsersSales);

// reducer
const userSalesReducer = handleActions({
    [`${GET_USERS_SALES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_USERS_SALES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),
  },
  initialState,
);

export default userSalesReducer;
