import React from 'react';
// store
import { useSelector } from 'react-redux';
import { getGiftsSales } from '../../store/modules/giftsSales';
// components
import { Table } from '../../components';
// styles
import styles from '../Gifts/Gifts.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Photo',
    field: 'photosPath',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    sorting: false,
    render: ({ photosPath }) =>
      photosPath ? (
        <img src={photosPath} alt='Gift' className={styles['gift-photo']} />
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { minWidth: 500 },
  },
  {
    title: 'User who added',
    field: 'firstName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (gift) => `${gift.firstName} ${gift.lastName}`,
  },
  {
    title: 'Price',
    field: 'price',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ currency, price }) => [price, currency?.sign],
  },
  {
    title: 'Currency',
    field: 'currencyCode',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currencyCode }) => [currencyCode],
  },
  {
    title: 'Domain name',
    field: 'link',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ link }) => link.replace(/.+\/\/|www.|\/.+/g, ''),
  },
  {
    title: 'Sales',
    field: 'salesCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Revenue',
    field: 'totalCommissionAmount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ totalCommissionAmount }) => [Math.trunc(totalCommissionAmount * 100) / 100],
  },
  {
    title: 'Last sale at',
    field: 'lastSaleAt',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onGiftSalesDetails }) => [
  () => ({
    name: 'Gift sales details',
    onClick: onGiftSalesDetails,
  }),
];

export const GiftsSales = ({ history, match }) => {
  const giftsSales = useSelector((state) => state.giftsSales);

  const onGiftSalesDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  return (
    <div>
      <h1 className={styles.title}>Gift's Sales</h1>

      <Table
        data={giftsSales}
        columns={columns}
        getData={getGiftsSales}
        defaultSort='lastSaleAt,DESC'
        actions={actions({onGiftSalesDetails})}
      />
    </div>
  );
};
