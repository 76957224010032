import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Input, StaticTable } from '../../components';
import styles from './ParserRulesDetails.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    width: 100,
  },
  {
    title: 'Name',
    field: 'host',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    width: '80%',
  },
];

const actions = ({ onDeleteParserHost }) => [
  (domain) => ({
    name: 'Delete',
    onClick: () => onDeleteParserHost(domain.id),
  }),
];

const validationSchema = Yup.object().shape({
  host: Yup.string()
    .matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'Invalid url',
    )
    .required('Domain is required'),
});

export const ParserRulesDomainsForm = ({ data, onSubmit, onDeleteParserHost, addDomainError }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { host: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Domains List</h2>
        <StaticTable
          staticData={data}
          columns={columns}
          actions={actions({ onDeleteParserHost })}
        />
      </div>

      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Add New Domain</h2>
        {!!addDomainError && <p className={styles.error}>Host already exists</p>}
        <Input
          label='Domain'
          name='host'
          placeholder='domain'
          onChange={formik.handleChange}
          value={formik.values.host}
          error={formik.touched.host && formik.errors.host}
        />

        <button type='submit' className={styles['button-submit']}>
          Add
        </button>
      </div>
    </form>
  );
};
