import React, { useEffect, useCallback } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getTopGifts, loadMoreTopGifts } from '../../store/modules/gifts';
import { getCategories } from '../../store/modules/categories';
import { getCurrency } from '../../store/modules/currency';
import { getCountries } from '../../store/modules/deals';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// utils
import { checkNumberValue } from '../../utils';
// styles
import styles from './TopGifts.module.css';

const columns = [
  {
    title: 'Total count',
    field: 'countRepeatedGift',
    emptyValue: 0,
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    sorting: false,
  },
  {
    title: 'Photo',
    field: 'photos',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    sorting: false,
    render: ({ photos }) =>
      photos.length ? (
        <img src={photos[0].path} alt='Gift' className={styles['gift-photo']} />
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { minWidth: 500 },
    sorting: false,
  },
  {
    title: 'User who added',
    field: 'user.firstName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ user }) => `${user.firstName} ${user.lastName}`,
    sorting: false,
  },
  {
    title: 'Description',
    field: 'description',
    emptyValue: 'N/A',
    headerStyle: { whiteSpace: 'nowrap' },
    cellStyle: { minWidth: 600 },
    sorting: false,
  },
  {
    title: 'Price',
    field: 'price',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ currency, price }) => [price, currency?.sign],
    sorting: false,
  },
  {
    title: 'Currency',
    field: 'currency.code',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currency }) => [currency?.code],
    sorting: false,
  },
  {
    title: 'Created time',
    field: 'createdAt',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
    sorting: false,
  },
  {
    title: 'Domain name',
    field: 'link',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ link }) => link.replace(/.+\/\/|www.|\/.+/g, ''),
    sorting: false,
  },
  {
    title: 'Category',
    field: 'category.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    sorting: false,
  },
];

const actions = ({ onUserDetails, onGiftDetails, onGiftPhotos }) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
];

const actionsForGiftAdmin = ({ onGiftDetails, onGiftPhotos }) => [
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
];

const giftsGetSearchParams = (columns, value) => {
  const fieldsNotSearch = [
    'follow',
    'photos',
    'isTimeout',
    'photo',
    'position',
    'user.accountTypeId',
    'currency',
    'description',
    'currency.code',
    'link',
    'category.name',
    'user.firstName',
  ];
  const filteredColumns = columns.filter(
    (column) => !column.type?.includes('date') && !fieldsNotSearch.includes(column.field),
  );

  return filteredColumns.map((column) => {
    return {
      [column.field]: column.type === 'numeric' ? checkNumberValue(value) : { $contL: value },
    };
  });
};

export const TopGiftsPage = ({ history, match }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers(
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
  );

  const dispatch = useDispatch();

  const gifts = useSelector((state) => state.gifts.topGifts);
  const userRole = useSelector((state) => state.auth.user.role);

  const onUserDetails = (rowData) => history.push(`/admin/users/${rowData.user.id}`);

  const onGiftDetails = (rowData) => history.push(`/admin/gifts/${rowData.id}`);

  const onGiftPhotos = (rowData) => history.push(`/admin/gifts/${rowData.id}/photos`);

  const handlerSearchParams = useCallback(
    ({ params }) => ({
      $and: [
        { 'user.id': match.params.id },
        {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        params,
      ],
    }),
    [match, startDate, endDate],
  );

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCurrency());
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div>
      <h1 className={styles.title}>Top Gifts</h1>

      <div className={styles.actions}>
        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      <Table
        data={gifts}
        getData={getTopGifts}
        columns={columns}
        defaultSort='createdAt,DESC'
        actions={
          userRole.id === 3
            ? actionsForGiftAdmin({ onGiftDetails, onGiftPhotos })
            : actions({
                onUserDetails,
                onGiftDetails,
                onGiftPhotos,
              })
        }
        searchParams={handlerSearchParams}
        advancedGetSearchParams={giftsGetSearchParams}
        defaultPageSize={50}
        isLoadMore
        loadMoreData={loadMoreTopGifts}
      />
    </div>
  );
};
