import React from 'react';
// components
import Select from 'react-select';
// styles
import styles from './Gifts.module.css';

const getOptions = (options) =>
  options?.map((option) => ({
    value: option.code,
    label: option.code,
    ...option,
  }));

const SearchOptions = ({ currency, onCurrencyChange, value }) => {
  return (
    <div className={styles.searchOption}>
      <p>Selected Currency:</p>
      <Select
        placeholder='Select a currency...'
        options={getOptions(currency)}
        value={value}
        onChange={(value) => onCurrencyChange(value)}
        isClearable
        className={styles.selectCurrency}
      />
    </div>
  );
};

export default SearchOptions;
