import React, { useRef, useState, useCallback } from 'react';
// store
import { useSelector, useDispatch } from 'react-redux';
import { getDeals, deleteDeal } from '../../store/modules/deals';
import Button from '@material-ui/core/Button';
//components
import { Table } from '../../components';
// styles
import styles from './Deals.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    width: 100,
  },
  {
    title: 'Photo',
    field: 'photo',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', maxWidth: '200px' },
    sorting: false,
    render: ({ photo }) => {
      return photo ? <img src={photo.path} alt='Gift' className={styles['gift-photo']} /> : 'N/A';
    },
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    width: '60%',
  },
  {
    title: 'Type',
    field: 'dealType.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Start Date',
    field: 'startDate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Expire time',
    field: 'expirationDate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Clicks',
    field: 'statistic.clicks',
    emptyValue: '0',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
  },
];

const actions = ({ onDealDetails, onDealDelete }) => [
  () => ({
    name: 'Deal details',
    onClick: onDealDetails,
  }),
  () => ({
    name: 'Delete',
    onClick: onDealDelete,
  }),
];

const Tabs = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

const additionalRequestParams = { join: 'statistic' };

export const DealsPage = ({ history, match }) => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const deals = useSelector((state) => state.deals);

  const [activeTab, setActiveTab] = useState(Tabs.active);

  const onCreateDeal = () => history.push(`${match.url}/create`);
  const onDealDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);
  const onDealDelete = (rowData, fetchData) => {
    dispatch(deleteDeal(rowData.id)).then(() =>
      fetchData(deals.count === 1 ? deals.page - 1 : deals.page, tableRef.current.pageSize),
    );
  };

  const handlerSearchParams = useCallback(
    ({ params }) =>
      activeTab === Tabs.active
        ? {
            $and: [
              {
                '"Deal"."startDate"': { $lte: new Date().toISOString() },
              },
              {
                '"Deal"."expirationDate"': { $gte: new Date().toISOString() },
              },
              params,
            ],
          }
        : {
            $and: [
              {
                $or: [
                  {
                    '"Deal"."startDate"': { $gte: new Date().toISOString() },
                  },
                  {
                    '"Deal"."expirationDate"': {
                      $lte: new Date().toISOString(),
                    },
                  },
                ],
              },
              params,
            ],
          },

    [activeTab],
  );

  return (
    <div>
      <h1 className={styles.title}>Deals</h1>
      <div className={styles.actions}>
        <button type='button' onClick={onCreateDeal} className={styles.button}>
          Create deal
        </button>
      </div>

      <div className={styles.tabs}>
        <Button
          variant={activeTab === Tabs.active ? 'contained' : 'outlined'}
          color='primary'
          className={styles.tabButton}
          onClick={() => setActiveTab(Tabs.active)}
        >
          Active deals
        </Button>
        <Button
          color='primary'
          variant={activeTab === Tabs.inactive ? 'contained' : 'outlined'}
          className={styles.tabButton}
          onClick={() => setActiveTab(Tabs.inactive)}
        >
          Inactive deals
        </Button>
      </div>

      <Table
        customRef={tableRef}
        data={deals}
        getData={getDeals}
        columns={columns}
        searchParams={handlerSearchParams}
        additionalRequestParams={additionalRequestParams}
        defaultSort='createdAt,DESC'
        actions={actions({ onDealDetails, onDealDelete })}
      />
    </div>
  );
};
