import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Dashboard.module.css';

export const Card = ({ count, text, icon, link, color }) => (
  <div className={styles.card}>
    <div className={styles.cardContent} style={{ backgroundColor: color, borderColor: color }}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <div className={styles.cardData}>
        <p className={styles.cardDataTitle}>{count}</p>
        <p className={styles.cardDataDesc}>{text}</p>
      </div>
    </div>
    <Link to={link} className={styles.cardLink}>
      View details
      <FontAwesomeIcon icon='arrow-circle-right' style={{ color }} />
    </Link>
  </div>
);

Card.defaultProps = {
  color: '#337ab7',
};
