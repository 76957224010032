import React from 'react';
// router
import { useHistory } from 'react-router-dom';
// formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
// components
import { Input } from '../../components';
import ImageUploader from 'react-images-upload';
// utils
import { resizeImage } from '../../utils';
// styles
import styles from './CategoriesDetails.module.css';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

export const CategoriesDetailsForm = ({ values, onSubmit }) => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      ...values,
      photoPreview: values?.photo?.path,
    } || { name: '' },
    onSubmit,
    validationSchema,
  });

  const handleChangeImage = async (images, imageURL) => {
    const resizedImage = await resizeImage(images[0], 300, 300);

    formik.setFieldValue('photo', resizedImage);
    formik.setFieldValue('photoPreview', imageURL[0]);
  };

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <ImageUploader
        withIcon={false}
        withLabel={false}
        withPreview
        singleImage
        imgExtension={['.jpg', '.png', '.jpeg']}
        maxFileSize={5242880}
        onChange={handleChangeImage}
        name='photo'
        defaultImages={formik.values.photoPreview ? [formik.values.photoPreview] : undefined}
      />
      <Input
        label='Name'
        name='name'
        placeholder='Name'
        onChange={formik.handleChange}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        isRequired
      />
      <button type='button' className={styles['button-back']} onClick={() => history.goBack()}>
        Back
      </button>
      <button type='submit' className={styles['button-submit']}>
        Save
      </button>
    </form>
  );
};
