import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui components
import { Box, CircularProgress } from '@material-ui/core';

export const PrivateRoute = ({ component: Component, ...props }) => {
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  if (isAuthenticated == null)
    return (
      <Box display='flex' alignItems='center' justifyContent='center' width='100vw' height='100vh'>
        <CircularProgress size={60} />
      </Box>
    );

  return (
    <Route
      {...props}
      render={(route) => (isAuthenticated ? <Component {...route} /> : <Redirect to='/sign-in' />)}
    />
  );
};
