import React from 'react';
// formik
import { useFormik, FormikProvider } from 'formik';
// components
import { Input, Checkbox } from '../../components';
import { ParserRulesFields } from './ParserRulesFields';
import { FieldGroup } from './FieldGroup';
// styles
import styles from './ParserRulesDetails.module.css';

const initialRuleValue = {
  selector: '',
  select_html: false,
  regexp: { pattern: '', replacement: '' },
  attribute: '',
  json_path: '',
};

const initialValues = {
  name: '',
  isSinglePageApplication: false,
  parserHosts: [null],
  rules: {
    title: [initialRuleValue],
    description: [initialRuleValue],
    price: [initialRuleValue],
    currency: [initialRuleValue],
    images: [initialRuleValue],
    isSoldOut: [initialRuleValue],
  },
};

export const ParserRulesForm = ({ values, onSubmit }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values
      ? {
          ...values,
          rules: {
            ...initialValues.rules,
            ...Object.fromEntries(
              Object.keys(values.rules).map((ruleKey) => [
                ruleKey,
                values.rules[ruleKey].map((rule) => ({
                  ...initialRuleValue,
                  ...rule,
                })),
              ]),
            ),
          },
          domain: '',
        }
      : initialValues,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          label='Name'
          name='name'
          placeholder='Name'
          onChange={formik.handleChange}
          value={formik.values.name}
        />

        <Checkbox
          label='Single Page Application'
          name='isSinglePageApplication'
          value={formik.values.isSinglePageApplication}
          checked={formik.values.isSinglePageApplication}
          onChange={() =>
            formik.setFieldValue(`isSinglePageApplication`, !formik.values.isSinglePageApplication)
          }
          className={styles.checkbox}
        />

        <FieldGroup
          name='rules.title'
          title='Title rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.title}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <FieldGroup
          name='rules.description'
          title='Description rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.description}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <FieldGroup
          name='rules.price'
          title='Price rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.price}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <FieldGroup
          name='rules.currency'
          title='Currency rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.currency}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <FieldGroup
          name='rules.images'
          title='Images rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.images}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <FieldGroup
          name='rules.isSoldOut'
          title='Sold Out rules'
          handleChange={formik.handleChange}
          values={formik.values.rules.isSoldOut}
          setFieldValue={formik.setFieldValue}
          initialValue={initialRuleValue}
          component={ParserRulesFields}
        />

        <button type='submit' className={styles['button-submit']}>
          Save
        </button>
      </form>
    </FormikProvider>
  );
};
