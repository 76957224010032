import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_GIFTS_SALES = 'giftsSales/GET_GIFTS_SALES';

// action creators
export const getGiftsSales = createAction(GET_GIFTS_SALES, api.giftsSales.getGiftsSales);

// reducer
const giftsSalesReducer = handleActions({
    [`${GET_GIFTS_SALES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_GIFTS_SALES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),
  },
  initialState,
);

export default giftsSalesReducer;
