import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  detailed: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
  tempParserHostId: null,
  parsed: null,
};

// types
export const GET_PARSER_RULES = 'parserRules/GET_PARSER_RULES';
export const GET_PARSER_RULE = 'parserRules/GET_PARSER_RULE';
export const CREATE_PARSER_RULE = 'parserRules/CREATE_PARSER_RULE';
export const UPDATE_PARSER_RULE = 'parserRules/UPDATE_PARSER_RULE';
export const DELETE_PARSER_RULE = 'parserRules/DELETE_PARSER_RULE';
export const CREATE_PARSER_HOST = 'parserRules/CREATE_PARSER_HOST';
export const GET_PARSER_HOSTS = 'parserRules/GET_PARSER_HOSTS';
export const DELETE_PARSER_HOST = 'parserRules/DELETE_PARSER_HOST';
export const TEST_PARSER_RULE = 'parserRules/TEST_PARSER_RULE';
export const CLEAR_TEST_PARSER_RULE = 'parserRules/CLEAR_TEST_PARSER_RULE';

// action creators
export const getParserRules = createAction(GET_PARSER_RULES, api.parserRules.getParserRules);
export const getParserRule = createAction(GET_PARSER_RULE, api.parserRules.getParserRule);
export const updateParserRule = createAction(UPDATE_PARSER_RULE, api.parserRules.updateParserRule);
export const createParserRule = createAction(CREATE_PARSER_RULE, api.parserRules.createParserRule);
export const createParserHost = createAction(CREATE_PARSER_HOST, api.parserHosts.createParserHost);
export const deleteParserRule = createAction(DELETE_PARSER_RULE, api.parserRules.deleteParserRule);
export const getParserHosts = createAction(GET_PARSER_HOSTS, api.parserHosts.getParserHosts);
export const deleteParserHost = createAction(DELETE_PARSER_HOST, (data) => ({
  promise: api.parserHosts.deleteParserHost(data),
  data,
}));
export const testParserRule = createAction(TEST_PARSER_RULE, api.parserRules.testParserRule);
export const clearTestParserRule = createAction(CLEAR_TEST_PARSER_RULE);

// reducer
const categoriesReducer = handleActions(
  {
    [combineActions(
      `${GET_PARSER_RULES}_REQUEST`,
      `${UPDATE_PARSER_RULE}_REQUEST`,
      `${TEST_PARSER_RULE}_REQUEST`,
    )]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${DELETE_PARSER_HOST}_REQUEST`]: (state, { payload }) => ({
      ...state,
      tempParserHostId: payload,
      isLoading: false,
    }),

    [`${TEST_PARSER_RULE}_SUCCESS`]: (state, { payload: { parsed } }) => ({
      ...state,
      parsed,
      isLoading: false,
    }),

    [`${GET_PARSER_RULE}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      details: payload,
      isLoading: false,
    }),

    [`${GET_PARSER_RULES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      list: data.sort((a, b) => b.isDefault - a.isDefault),
      isLoading: false,
      ...rest,
    }),

    [`${UPDATE_PARSER_RULE}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      details: payload,
      isLoading: false,
    }),

    [`${DELETE_PARSER_HOST}_SUCCESS`]: (state) => ({
      ...state,
      details: {
        ...state.details,
        parserHosts: state.details.parserHosts.filter((host) => host.id !== state.tempParserHostId),
      },
      isLoading: false,
    }),

    [CLEAR_TEST_PARSER_RULE]: (state) => ({
      ...state,
      parsed: null,
      isLoading: false,
    }),

    [combineActions(
      `${GET_PARSER_RULES}_ERROR`,
      `${GET_PARSER_RULE}_ERROR`,
      `${UPDATE_PARSER_RULE}_ERROR`,
    )]: () => initialState,
  },
  initialState,
);

export default categoriesReducer;
