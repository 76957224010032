import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Input, RadioButton, DatePicker, Checkbox } from '../../components';
import styles from './UserDetails.module.css';

const genderOptions = [
  {
    id: 1,
    name: 'Female',
  },
  {
    id: 2,
    name: 'Male',
  },
  {
    id: 3,
    name: 'Other',
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  birthDate: Yup.string().nullable().required('Birth date is required'),
  isInfluencer: Yup.boolean(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  birthDate: null,
  isInfluencer: false,
  payPalEmail: '',
  payoutAccountHolder: '',
  payoutAccountNumber: '',
  payoutRoutingNumber: '',
};

export const UserDetailsForm = ({ values, onSubmit }) => {
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...values, payPalEmail: values.payPalEmail || '' } || initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <Input
        label='ID'
        name='id'
        placeholder='ID'
        onChange={formik.handleChange}
        value={formik.values.id}
        disabled
      />
      <Input
        label='First name'
        name='firstName'
        placeholder='First name'
        onChange={formik.handleChange}
        value={formik.values.firstName}
        onBlur={formik.handleBlur}
        error={formik.touched.firstName && formik.errors.firstName}
      />
      <Input
        label='Last name'
        name='lastName'
        placeholder='Last name'
        onChange={formik.handleChange}
        value={formik.values.lastName}
        onBlur={formik.handleBlur}
        error={formik.touched.lastName && formik.errors.lastName}
      />
      <Input
        label='E-mail'
        name='email'
        type='email'
        placeholder='E-mail'
        onChange={formik.handleChange}
        value={formik.values.email}
        onBlur={formik.handleBlur}
        error={formik.touched.email && formik.errors.email}
      />
      <DatePicker
        variant='dialog'
        ampm={false}
        name='birthDate'
        label='Birth date'
        value={formik.values.birthDate}
        onChange={(date) => formik.setFieldValue('birthDate', date && date.toISO())}
        format='yyyy/MM/dd HH:mm'
        className={styles.datepicker}
        onBlur={formik.handleBlur}
        error={formik.touched.birthDate && formik.errors.birthDate}
      />
      <div className={styles['radio-wrapper']}>
        <label className={styles.label}>Sex type</label>
        {genderOptions.map((option) => (
          <RadioButton
            key={option.id}
            name='gender'
            value={option}
            label={option.name}
            checked={formik.values.gender?.name === option.name}
            onChange={() => formik.setFieldValue('gender', option)}
          />
        ))}
      </div>
      <Input
        label='Created time'
        name='createdAt'
        placeholder='Created time'
        onChange={formik.handleChange}
        value={formik.values.createdAt}
        disabled
      />
      <div className={styles.checkboxContainer}>
        <Checkbox
          label='Is Influencer'
          value={formik.values.isInfluencer}
          checked={formik.values.isInfluencer}
          onChange={() => formik.setFieldValue(`isInfluencer`, !formik.values.isInfluencer)}
          className={styles.checkbox}
        />
      </div>
      <span className={styles.paymentInformationHeader}>Payment Information</span>
      <Grid container spacing={3}>
        <Grid
          container
          direction='column'
          item
          xs={6}
          className={styles.paymentInformationContainer}
        >
          <span className={styles.paymentInformationTitle}>PayPal</span>
          <Input
            name='payPalEmail'
            type='email'
            placeholder='PayPal E-mail'
            onChange={formik.handleChange}
            value={formik.values.payPalEmail}
            onBlur={formik.handleBlur}
            error={formik.touched.payPalEmail && formik.errors.payPalEmail}
          />
        </Grid>
        <Grid
          container
          direction='column'
          item
          xs={6}
          className={styles.paymentInformationContainer}
        >
          <span className={styles.paymentInformationTitle}>Bank information</span>
          <Input
            name='payoutAccountHolder'
            placeholder='Payout account holder'
            onChange={formik.handleChange}
            value={formik.values.payoutAccountHolder}
            onBlur={formik.handleBlur}
            error={formik.touched.payoutAccountHolder && formik.errors.payoutAccountHolder}
          />
          <Input
            name='payoutAccountNumber'
            placeholder='Payout account number'
            onChange={formik.handleChange}
            value={formik.values.payoutAccountNumber}
            onBlur={formik.handleBlur}
            error={formik.touched.payoutAccountNumber && formik.errors.payoutAccountNumber}
          />
          <Input
            name='payoutRoutingNumber'
            placeholder='Payout routing number'
            onChange={formik.handleChange}
            value={formik.values.payoutRoutingNumber}
            onBlur={formik.handleBlur}
            error={formik.touched.payoutRoutingNumber && formik.errors.payoutRoutingNumber}
          />
        </Grid>
      </Grid>
      <button type='button' className={styles['button-back']} onClick={() => history.goBack()}>
        Back
      </button>
      <button type='submit' className={styles['button-submit']}>
        Save
      </button>
    </form>
  );
};
