import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  details: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
  countries: null,
};

// types
export const GET_DEALS = 'deals/GET_DEALS';
export const GET_DEAL = 'deals/GET_DEAL';
export const GET_COUNTRIES = 'deals/GET_COUNTRIES';
export const UPDATE_DEAL = 'deals/UPDATE_DEAL';
export const CREATE_DEAL = 'deals/CREATE_DEAL';
export const DELETE_DEAL = 'deals/DELETE_DEAL';

// action creators
export const getDeals = createAction(GET_DEALS, api.deals.getDeals);
export const getDeal = createAction(GET_DEAL, api.deals.getDeal);
export const getCountries = createAction(GET_COUNTRIES, api.countries.getCountries);
export const updateDeal = createAction(UPDATE_DEAL, api.deals.updateDeal);
export const createDeal = createAction(CREATE_DEAL, api.deals.createDeal);
export const deleteDeal = createAction(DELETE_DEAL, api.deals.deleteDeal);

// reducer
const dealsReducer = handleActions(
  {
    [combineActions(`${GET_DEALS}_REQUEST`, `${GET_DEAL}_REQUEST`, `${GET_COUNTRIES}_REQUEST`)]: (
      state,
    ) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_DEALS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      list: data,
      isLoading: false,
      ...rest,
    }),

    [`${GET_DEAL}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      details: payload,
      isLoading: false,
    }),

    [`${GET_COUNTRIES}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      countries: payload,
      isLoading: false,
    }),

    [combineActions(`${GET_DEALS}_ERROR`, `${GET_DEAL}_ERROR`, `${GET_COUNTRIES}_ERROR`)]: () =>
      initialState,
  },
  initialState,
);

export default dealsReducer;
