import React, { useRef, useCallback } from 'react';
// store
import { useSelector, useDispatch } from 'react-redux';
import { getParserRules, deleteParserRule } from '../../store/modules/parserRules';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './ParserRules.module.css';

const columns = [
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onParserDetails, onDeleteParserRule, onCopyParserRule }) => [
  () => ({
    name: 'Details',
    onClick: onParserDetails,
  }),
  () => ({
    name: 'Copy',
    onClick: onCopyParserRule,
  }),
  (parserRule) => ({
    name: 'Delete',
    onClick: onDeleteParserRule,
    isDisabled: !!parserRule.parserHosts.length,
  }),
];

export const ParserRulesPage = ({ history, match }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();

  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const parserRules = useSelector((state) => state.parserRules);

  const onParserDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  const onCreateParserRule = () => history.push(`${match.url}/create`);

  const onCopyParserRule = ({ id, ...data }) =>
    history.push(`${match.url}/create`, { ...data, parserHosts: [] });

  const onDeleteParserRule = (rowData, fetchData) =>
    dispatch(deleteParserRule(rowData.id)).then(() =>
      fetchData(
        parserRules.count === 1 ? parserRules.page - 1 : parserRules.page,
        tableRef.current.pageSize,
      ),
    );

  const handlerSearchParams = useCallback(
    ({ params, search }) => ({
      $and: [
        !search && {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        !search && {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        params,
      ],
    }),
    [startDate, endDate],
  );

  return (
    <div>
      <h1 className={styles.title}>Parser rules</h1>

      <div className={styles.actions}>
        <button type='button' onClick={onCreateParserRule} className={styles.button}>
          Create parser rule
        </button>

        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      <Table
        customRef={tableRef}
        data={parserRules}
        getData={getParserRules}
        searchParams={handlerSearchParams}
        defaultSort='createdAt,DESC'
        actions={actions({
          onParserDetails,
          onCopyParserRule,
          onDeleteParserRule,
        })}
        columns={columns}
      />
    </div>
  );
};
