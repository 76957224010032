import React from 'react';
// store
import { useDispatch } from 'react-redux';
import { createParserRule } from '../../store/modules/parserRules';
// components
import { ParserRulesForm } from '../ParserRulesDetails/ParserRulesForm';
import { ParserRulesTestAddressForm } from '../ParserRulesDetails/ParserRulesTestForm';
// styles
import styles from './ParserRulesCreate.module.css';

export const ParserRulesCreatePage = ({ history, location }) => {
  const dispatch = useDispatch();

  const onCreateParserRule = (values) =>
    dispatch(createParserRule(values)).then(() => history.goBack());

  return (
    <div>
      <h1 className={styles.title}>Create parser rule</h1>

      <ParserRulesForm values={location.state} onSubmit={onCreateParserRule} />
      <ParserRulesTestAddressForm />
    </div>
  );
};
