import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  detailed: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_CATEGORIES = 'categories/GET_CATEGORIES';
export const UPDATE_CATEGORY = 'categories/UPDATE_CATEGORY';
export const CREATE_CATEGORY = 'categories/CREATE_CATEGORY';

// action creators
export const getCategories = createAction(GET_CATEGORIES, api.categories.getCategories);
export const updateCategory = createAction(UPDATE_CATEGORY, api.categories.updateCategory);
export const createCategory = createAction(CREATE_CATEGORY, api.categories.createCategory);

// reducer
const categoriesReducer = handleActions(
  {
    [`${GET_CATEGORIES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_CATEGORIES}_SUCCESS`]: (state, { payload }) => {
      if (Array.isArray(payload)) {
        return {
          ...state,
          list: payload,
          isLoading: false,
        };
      }

      const { data, ...rest } = payload;

      return {
        ...state,
        list: data,
        isLoading: false,
        ...rest,
      };
    },

    [combineActions(`${GET_CATEGORIES}_ERROR`)]: () => initialState,
  },
  initialState,
);

export default categoriesReducer;
