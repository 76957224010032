import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  detailed: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_PARSER_FAILS = 'parserFails/GET_PARSER_FAILS';
export const UPDATE_PARSER_FAIL = 'parserFails/UPDATE_PARSER_FAIL';

// action creators
export const getParserFails = createAction(GET_PARSER_FAILS, api.parserFails.getParserFails);
export const updateParserFail = createAction(UPDATE_PARSER_FAIL, api.parserFails.updateParserFail);

// reducer
const categoriesReducer = handleActions(
  {
    [combineActions(`${GET_PARSER_FAILS}_REQUEST`)]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_PARSER_FAILS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      list: data,
      isLoading: false,
      ...rest,
    }),

    [combineActions(`${GET_PARSER_FAILS}_ERROR`)]: () => initialState,
  },
  initialState,
);

export default categoriesReducer;
