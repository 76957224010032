import React, {useEffect, useMemo} from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getUserSales } from '../../store/modules/userSales';
// components
import { Table } from '../../components';
// styles
import styles from '../Gifts/Gifts.module.css';
import { getUser } from '../../store/modules/users';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Sale ID',
    field: 'saleId',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'External commission amount',
    field: 'externalCommissionAmount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ externalCommissionAmount }) => [Math.trunc(externalCommissionAmount * 100) / 100],
  },
  {
    title: 'Internal Commission Rate',
    field: 'internalCommissionRate',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ internalCommissionRate }) => [Math.trunc(internalCommissionRate * 100) / 100],
  },
  {
    title: 'Currency',
    field: 'currency',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currency }) => [currency.code],
  },
  {
    title: 'Status',
    field: 'externalPaymentStatus',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ externalPaymentStatus }) => [externalPaymentStatus.name],
  },
  {
    title: 'Transaction date',
    field: 'transactionDate',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

export const UserSalesDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const userSales = useSelector((state) => state.userSales);

  const additionalParams = useMemo(() => ({ userId: match.params.id }), [match.params.id]);
  const countryName = user?.country?.name ? `, ${user.country.name}` : null;

  useEffect(() => {
    dispatch(getUser(match.params.id));
  }, [dispatch, match]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>
        User's sales details
      </h1>
      <h2>
        #{user?.id} {user?.firstName} {user?.lastName}{countryName}
      </h2>

      <Table
        data={userSales}
        columns={columns}
        getData={getUserSales}
        additionalRequestParams={additionalParams}
        defaultSort='transactionDate,DESC'
      />
    </div>
  );
};
