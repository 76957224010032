import React, { useEffect, useState } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import {
  getParserRule,
  updateParserRule,
  createParserHost,
  deleteParserHost,
  getParserHosts,
} from '../../store/modules/parserRules';
// components
import { ParserRulesForm } from './ParserRulesForm';
import { ParserRulesDomainsForm } from './ParserRulesDomainsForm';
import { ParserRulesTestAddressForm } from './ParserRulesTestForm';
// styles
import styles from './ParserRulesDetails.module.css';

export const ParserRulesDetailsPage = ({ match }) => {
  const dispatch = useDispatch();
  const parserRule = useSelector((state) => state.parserRules.details);
  const [addDomainError, setAddDomainError] = useState('');

  const onUpdateParserRule = (values) => dispatch(updateParserRule(values.id, values));

  const onAddNewDomain = (values) => {
    setAddDomainError('');

    dispatch(
      getParserHosts({
        s: {
          $and: [{ host: { $eq: values.host.replace(/.+\/\/|www.|\/.+/g, '') } }],
        },
      }),
    )
      .then((res) => {
        if (res?.value?.data?.length > 0) {
          const host = res.value.data[0];

          dispatch(
            updateParserRule(parserRule.id, {
              parserHosts: [...parserRule.parserHosts, { id: host.id }],
            }),
          );
        } else {
          dispatch(
            createParserHost({
              host: values.host.replace(/.+\/\/|www.|\/.+/g, ''),
            }),
          )
            .then((response) => {
              dispatch(
                updateParserRule(parserRule.id, {
                  parserHosts: [...parserRule.parserHosts, { id: response.value.id }],
                }),
              );
            })
            .catch((e) => {
              setAddDomainError(e.message);
            });
        }
      })
      .catch((e) => {
        setAddDomainError(e.message);
      });
  };

  const onDeleteParserHost = (id) => dispatch(deleteParserHost(id));

  useEffect(() => {
    dispatch(getParserRule(match.params.id));
  }, [dispatch, match]);

  if (!parserRule) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>{`#${parserRule.id} ${parserRule.name}`}</h1>

      <ParserRulesForm values={parserRule} onSubmit={onUpdateParserRule} />

      <ParserRulesDomainsForm
        data={parserRule.parserHosts}
        onSubmit={onAddNewDomain}
        onDeleteParserHost={onDeleteParserHost}
        addDomainError={addDomainError}
      />

      <ParserRulesTestAddressForm />
    </div>
  );
};
