import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  authenticated: null,
  user: {},
};

// types
export const SIGN_IN = 'auth/SIGN_IN';
export const LOG_OUT = 'auth/LOG_OUT';
export const GET_PROFILE = 'auth/GET_PROFILE';
export const SET_AUTHENTICATED = 'auth/SET_AUTHENTICATED';

// action creators
export const signInRequest = createAction(SIGN_IN, api.auth.signIn);
export const getProfile = createAction(GET_PROFILE, api.auth.getProfile);
export const logOutRequest = createAction(LOG_OUT);
export const setAuthenticated = createAction(SET_AUTHENTICATED);

// reducer
const authReducer = handleActions(
  {
    [combineActions(`${SIGN_IN}_SUCCESS`, `${GET_PROFILE}_SUCCESS`)]: (state, { payload }) => ({
      ...state,
      authenticated: true,
      user: payload.user || payload,
    }),

    [combineActions(`${SIGN_IN}_ERROR`, LOG_OUT)]: () => ({
      initialState,
      authenticated: false,
    }),

    [SET_AUTHENTICATED]: (state, { payload }) => ({
      ...state,
      authenticated: payload,
    }),
  },
  initialState,
);

export default authReducer;
