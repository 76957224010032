import React from 'react';
import { VictoryPie, VictoryLabel, VictoryTheme } from 'victory';

const getDataForChart = (data) =>
  data
    .filter((item) => item.userCount)
    .map((item) => ({
      x: item.name || 'Unknown',
      y: item.userCount,
    }));

export const PieChart = ({ data, ...props }) => (
  <VictoryPie
    data={getDataForChart(data)}
    labels={({ datum, data }) => {
      const total = data.reduce((sum, item) => sum + item.y, 0);
      const percentage = Math.round((datum.y / total) * 100);
      return [datum.x, ` ${percentage}%`];
    }}
    labelPosition='centroid'
    labelPlacement='parallel'
    theme={VictoryTheme.material}
    labelComponent={
      <VictoryLabel
        inline
        style={[
          {
            fontFamily: "'Helvetica Neue', Helvetica, 'Roboto', sans-serif",
          },
          {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: "'Helvetica Neue', Helvetica, 'Roboto', sans-serif",
          },
        ]}
      />
    }
    style={{
      parent: { width: 500, height: 500 },
    }}
    {...props}
  />
);
