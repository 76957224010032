import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Input, Textarea, Select, RadioButton } from '../../components';
import styles from './GiftDetails.module.css';
// validation
import { validationSchema } from '../GiftCreate/GiftCreateForm';

const statusOptions = [
  {
    id: 1,
    name: 'Active',
  },
  {
    id: 2,
    name: 'Reserved',
  },
];

export const GiftDetailsForm = ({ values, categories, onSubmit, currency, countries }) => {
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values || {},
    onSubmit,
    validationSchema,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <Input
        label='ID'
        name='id'
        placeholder='ID'
        onChange={formik.handleChange}
        value={formik.values.id}
        disabled
      />
      <Input
        label='Name'
        name='name'
        placeholder='Name'
        onChange={formik.handleChange}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        isRequired
      />
      <Input
        label='Price'
        name='price'
        placeholder='Price'
        type='number'
        step='0.01'
        onChange={formik.handleChange}
        value={formik.values.price || ''}
        onBlur={formik.handleBlur}
        error={formik.touched.price && formik.errors.price}
        isRequired
      />
      <Input
        label='Link to store'
        name='link'
        placeholder='Link to store'
        onChange={formik.handleChange}
        value={formik.values.link}
        onBlur={formik.handleBlur}
        error={formik.touched.link && formik.errors.link}
        isRequired
      />
      <Textarea
        label='Description'
        name='description'
        placeholder='Description'
        onChange={formik.handleChange}
        value={formik.values.description}
      />
      <Select
        label='Category'
        name='category'
        options={categories.list}
        value={formik.values.category}
        onChange={(value) => formik.setFieldValue('category', value)}
        onBlur={formik.handleBlur}
        error={formik.touched.category && formik.errors.category}
        isRequired
      />
      <Select
        label='Currency'
        name='currency'
        options={Object.values(currency)}
        value={formik.values.currency}
        onChange={(value) => formik.setFieldValue('currency', value)}
        onBlur={formik.handleBlur}
        error={formik.touched.currency && formik.errors.currency}
        isRequired
      />

      <Select
        isMulti
        label='Countries'
        name='countries'
        options={countries}
        value={formik.values.countries}
        onChange={(value) => formik.setFieldValue('countries', value)}
      />

      <Input
        label='User ID'
        name='user.id'
        placeholder='User ID'
        onChange={formik.handleChange}
        value={formik.values.user.id}
        disabled
      />
      <Input
        label='User reserved'
        name='reservedBy.id'
        placeholder='User reserved'
        onChange={formik.handleChange}
        value={formik.values.reservedBy?.id}
        disabled
      />
      {!!values.wishlist?.name && (
        <Input label='Wishlist' placeholder='Wishlist' value={values.wishlist?.name} disabled />
      )}

      <div className={styles['radio-group']}>
        <label className={styles.label}>Gift status</label>

        <div className={styles['radio-wrapper']}>
          {statusOptions.map((option) => (
            <RadioButton
              key={option.id}
              label={option.name}
              name='status'
              value={option}
              checked={formik.values.status?.name === option.name}
              onChange={() => formik.setFieldValue('status', option)}
            />
          ))}
        </div>
        {formik.touched.status && formik.errors.status && (
          <p className={styles.error}>{formik.errors.status}</p>
        )}
      </div>

      <Input
        label='Created time'
        name='createdAt'
        placeholder='Created time'
        onChange={formik.handleChange}
        value={formik.values.createdAt}
        disabled
      />
      <button type='button' className={styles['button-back']} onClick={() => history.goBack()}>
        Back
      </button>
      <button type='submit' className={styles['button-submit']}>
        Save
      </button>
    </form>
  );
};
