import React from 'react';
import RIImageUploader from 'react-images-upload';
import styles from './Form.module.css';

export const ImageUploader = ({ error, ...rest }) => (
  <>
    <RIImageUploader {...rest} />
    {error && <p className={styles.error}>{error}</p>}
  </>
);
