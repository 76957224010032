import React, { useState, useCallback, useEffect } from 'react';
// store
import { useSelector, useDispatch } from 'react-redux';
import { getGifts, removeGiftCategory } from '../../store/modules/gifts';
import { getCurrency } from '../../store/modules/currency';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
import SearchOptions from '../Gifts/SearchOptions';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './CategoriesGifts.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Photo',
    field: 'photos',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    sorting: false,
    render: ({ photos }) =>
      photos.length ? (
        <img src={photos[0].path} alt='Gift' className={styles['gift-photo']} />
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { minWidth: 500 },
  },
  {
    title: 'User who added',
    field: 'user.firstName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ user }) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Price',
    field: 'price',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
    render: ({ currency, price }) => [price, currency?.sign],
  },
  {
    title: 'Currency',
    field: 'currency.code',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currency }) => [currency?.code],
    // sorting: false,
  },
  {
    title: 'Created time',
    field: 'createdAt',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Domain name',
    field: 'link',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ link }) => link.replace(/.+\/\/|www.|\/.+/g, ''),
  },
  {
    title: 'from Private Account?',
    field: 'user.accountTypeId',
    emptyValue: 'No',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ user }) => [user.accountTypeId === 2 ? 'Yes' : 'No'],
    sorting: false,
  },
];

const actions = ({ onUserDetails, onGiftDetails, onGiftPhotos }) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
];

const actionsForGiftAdmin = ({ onGiftDetails, onGiftPhotos, onGiftHide }) => [
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
];

export const CategoriesGiftsPage = ({ match, history }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();

  const [selectedCurrency, setSelectedCurrency] = useState(undefined);
  const [selectedGifts, setSelectedGifts] = useState([]);

  const dispatch = useDispatch();

  const currency = useSelector((state) => state.currency);
  const gifts = useSelector((state) => state.gifts);
  const userRole = useSelector((state) => state.auth.user.role);
  const category = useSelector((state) =>
    state.categories.list.find((category) => category.id === parseFloat(match.params.id)),
  );

  const onUserDetails = (rowData) => history.push(`/admin/users/${rowData.user.id}`);

  const onGiftDetails = (rowData) => history.push(`/admin/gifts/${rowData.id}`);

  const onGiftPhotos = (rowData) => history.push(`/admin/gifts/${rowData.id}/photos`);

  const onRowClick = (event, rowData) => {
    const giftIDs = selectedGifts.includes(rowData.id)
      ? selectedGifts.filter((gift) => gift !== rowData.id)
      : [...selectedGifts, rowData.id];

    setSelectedGifts(giftIDs);
  };

  const onRemoveCategory = () => {
    selectedGifts.forEach((giftID) => {
      dispatch(removeGiftCategory(giftID, { category: null }));
    });
  };

  const handlerSearchParams = useCallback(
    ({ params, search }) => ({
      $and: [
        { 'category.id': category.id },
        !search && {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        !search && {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        selectedCurrency?.value && {
          'currency.code': { $eq: selectedCurrency?.value },
        },
        params,
      ],
    }),
    [startDate, endDate, category, selectedCurrency],
  );

  useEffect(() => {
    dispatch(getCurrency());
  }, [dispatch]);

  return (
    <div>
      <h1 className={styles.title}>Gifts: {category.name}</h1>

      <div></div>
      <button type='button' className={styles.button} onClick={onRemoveCategory}>
        Remove from category
      </button>

      <DatePickers
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />

      <SearchOptions
        currency={currency.list}
        onCurrencyChange={setSelectedCurrency}
        value={selectedCurrency}
      />

      <Table
        data={gifts}
        getData={getGifts}
        columns={columns}
        defaultSort='createdAt,DESC'
        actions={
          userRole.id === 3
            ? actionsForGiftAdmin({ onGiftDetails, onGiftPhotos })
            : actions({ onUserDetails, onGiftDetails, onGiftPhotos })
        }
        options={{
          search: false,
          rowStyle: (rowData) => ({
            backgroundColor: selectedGifts.includes(rowData.id) && 'rgba(0, 0, 0, 0.08)',
          }),
        }}
        searchParams={handlerSearchParams}
        onRowClick={onRowClick}
      />
    </div>
  );
};
