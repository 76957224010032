import React from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { createGift, uploadPhoto } from '../../store/modules/gifts';
// components
import { GiftCreateForm } from './GiftCreateForm';
// styles
import styles from './GiftCreate.module.css';

export const GiftCreatePage = ({ match, history }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);
  const currency = useSelector((state) => state.currency);
  const countries = useSelector((state) => state.deals.countries);

  const profile = useSelector((state) => state.auth.user);

  const onCreateGift = (values) => {
    const photosPromise = values.photos.map((photo) => {
      const formData = new FormData();

      formData.append('file', photo);

      return dispatch(uploadPhoto(formData));
    });

    Promise.all(photosPromise).then((response) => {
      const photos = response.map((photo) => photo.value);

      dispatch(
        createGift({
          ...values,
          photos,
          price: parseFloat(values.price),
          user: profile,
        }),
      ).then(() => history.goBack());
    });
  };

  return (
    <div>
      <h1 className={styles.title}>Create gift</h1>

      <GiftCreateForm
        onSubmit={onCreateGift}
        categories={categories}
        currency={currency}
        countries={countries}
      />
    </div>
  );
};
