// pages
import { GiftsPage } from '../Gifts/Gifts';
import { TopGiftsPage } from '../TopGifts/TopGifts';
import { UsersPage } from '../Users/Users';
import { DashboardPage } from '../Dashboard/Dashboard';
import { GiftCreatePage } from '../GiftCreate/GiftCreate';
import { UserDetailsPage } from '../UserDetails/UserDetails';
import { GiftDetailsPage } from '../GiftDetails/GiftDetails';
import { GiftPhotosPage } from '../GiftPhotos/GiftPhotos';
import { CategoriesPage } from '../Categories/Categories';
import { CategoriesDetailsPage } from '../CategoriesDetails/CategoriesDetails';
import { CategoriesGiftsPage } from '../CategoriesGifts/CategoriesGifts';
import { CategoriesCreatePage } from '../CategoriesCreate/CategoriesCreate';
import { ParserRulesPage } from '../ParserRules/ParserRules';
import { ParserRulesDetailsPage } from '../ParserRulesDetails/ParserRulesDetails';
import { ParserRulesCreatePage } from '../ParserRulesCreate/ParserRulesCreate';
import { ParserFailsPage } from '../ParserFails/ParserFails';
import { FollowersPage } from '../Followers/Followers';
import { FollowingsPage } from '../Followings/Followings';
import { DealsPage } from '../Deals/Deals';
import { DealsDetailsPage } from '../DealsDetails/DealsDetails';
import { DealsCreatePage } from '../DealsCreate/DealsCreate';
import { ReportsPage } from '../Reports/Reports';
import { SystemSettingsPage } from '../SystemSettings/SystemSettings';
import { AdminDetailsPage } from '../AdminDetails/AdminDetails';
import { AdminCreatePage } from '../AdminCreate/AdminCreate';
import { UserDealsPage } from '../UserDeals/UserDeals';
import { CategoriesPositionPage } from '../CategoriesPosition/CategoriesPosition';
import { Influencers } from '../Influencers/Influencers';
import { UsersSales } from '../UsersSales/UsersSales';
import { GiftsSales } from '../GiftsSales/GiftsSales';
import { GiftSalesDetails } from '../GiftSalesDetails/GiftSalesDetails';
import { UserSalesDetails } from '../UserSalesDetails/UserSalesDetails';

export const routes = [
  { path: '/dashboard', component: DashboardPage },
  { path: '/users', component: UsersPage, exact: true },
  { path: '/users/:id', component: UserDetailsPage, exact: true },
  { path: '/users/:id/followers', component: FollowersPage },
  { path: '/users/:id/followings', component: FollowingsPage },
  { path: '/users/:id/gifts', component: GiftsPage },
  { path: '/gifts', component: GiftsPage, exact: true },
  { path: '/top-gifts', component: TopGiftsPage, exact: true },
  { path: '/gifts/create', component: GiftCreatePage },
  { path: '/gifts/:id', component: GiftDetailsPage, exact: true },
  { path: '/gifts/:id/photos', component: GiftPhotosPage },
  { path: '/categories', component: CategoriesPage, exact: true },
  { path: '/categories/create', component: CategoriesCreatePage },
  { path: '/categories/positions', component: CategoriesPositionPage },
  { path: '/categories/:id', component: CategoriesDetailsPage, exact: true },
  { path: '/categories/:id/gifts', component: CategoriesGiftsPage },
  { path: '/parser-rules', component: ParserRulesPage, exact: true },
  { path: '/parser-rules/create', component: ParserRulesCreatePage },
  { path: '/parser-rules/:id', component: ParserRulesDetailsPage },
  { path: '/parser-fails', component: ParserFailsPage, exact: true },
  { path: '/deals', component: DealsPage, exact: true },
  { path: '/users/:id/deals', component: UserDealsPage, exact: true },
  { path: '/deals/create', component: DealsCreatePage },
  { path: '/deals/:id', component: DealsDetailsPage },
  { path: '/reports', component: ReportsPage, exact: true },
  { path: '/settings', component: SystemSettingsPage, exact: true },
  { path: '/settings/administrator/create', component: AdminCreatePage },
  { path: '/settings/administrator/:id', component: AdminDetailsPage },
  { path: '/influencers', component: Influencers, exact: true },
  { path: '/users-sales', component: UsersSales, exact: true },
  { path: '/users-sales/:id', component: UserSalesDetails, exact: true },
  { path: '/gifts-sales', component: GiftsSales , exact: true },
  { path: '/gifts-sales/:id', component: GiftSalesDetails, exact: true },
  { path: '/influencers/:id', component: UserDetailsPage, exact: true },
  { path: '/influencers/:id/followers', component: FollowersPage },
  { path: '/influencers/:id/followings', component: FollowingsPage },
  { path: '/influencers/:id/gifts', component: GiftsPage },
  { path: '/influencers/:id/deals', component: UserDealsPage, exact: true },
];
