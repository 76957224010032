import { createAction, handleActions, combineActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  user: null,
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
  influencersStatistic: null,
};

// types
export const GET_USERS = 'users/GET_USERS';
export const GET_USER = 'users/GET_USER';
export const UPDATE_USER = 'users/UPDATE_USER';
export const DELETE_USER = 'users/DELETE_USER';
export const CREATE_USER = 'users/CREATE_USER';
export const HANDLE_PAY = 'influencers/HANDLE_PAY';
export const GET_STATISTIC = 'influencers/GET_STATISTIC';

// action creators
export const getUsers = createAction(GET_USERS, api.users.getUsers);
export const getUser = createAction(GET_USER, api.users.getUser);
export const updateUser = createAction(UPDATE_USER, api.users.updateUser);
export const deleteUser = createAction(DELETE_USER, api.users.deleteUser);
export const createUser = createAction(CREATE_USER, api.users.createUser);
export const handlePay = createAction(HANDLE_PAY, api.influencers.handlePay);
export const getStatistic = createAction(GET_STATISTIC, api.influencers.getInfluencersStatistic);

// reducer
const usersReducer = handleActions(
  {
    [`${GET_USERS}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_USERS}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),

    [`${GET_USER}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      user: payload,
      isLoading: false,
    }),

    [`${UPDATE_USER}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      user: payload,
      list: state.list.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
      isLoading: false,
    }),

    [`${GET_STATISTIC}_REQUEST`]: (state) => ({
      ...state,
      influencersStatistic: null,
    }),

    [`${GET_STATISTIC}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      influencersStatistic: payload,
      isLoading: false,
    }),

    [combineActions(`${GET_USERS}_ERROR`, `${GET_USER}_ERROR`, `${UPDATE_USER}_ERROR`)]: () =>
      initialState,
  },
  initialState,
);

export default usersReducer;
