import React, { useEffect, useState, useCallback } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getGifts, updateGift } from '../../store/modules/gifts';
import { getCategories } from '../../store/modules/categories';
import { getCurrency } from '../../store/modules/currency';
import { getCountries } from '../../store/modules/deals';
// components
import { GiftsForm } from './GiftsForm';
import { Table } from '../../components';
import { Checkbox } from '../../components';
import DatePickers from '../../components/DatePickers';
import SearchOptions from './SearchOptions';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// utils
import { checkNumberValue } from '../../utils';
// styles
import styles from './Gifts.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Photo',
    field: 'photos',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    sorting: false,
    render: ({ photos }) =>
      photos.length ? (
        <img src={photos[0].path} alt='Gift' className={styles['gift-photo']} />
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { minWidth: 500 },
  },
  {
    title: 'User who added',
    field: 'user.firstName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ user }) => `${user.firstName} ${user.lastName}`,
  },
  {
    title: 'Description',
    field: 'description',
    emptyValue: 'N/A',
    headerStyle: { whiteSpace: 'nowrap' },
    cellStyle: { minWidth: 600 },
  },
  {
    title: 'Price',
    field: 'price',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ currency, price }) => [price, currency?.sign],
  },
  {
    title: 'Currency',
    field: 'currency.code',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ currency }) => [currency?.code],
  },
  {
    title: 'Created time',
    field: 'createdAt',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'datetime',
  },
  {
    title: 'Domain name',
    field: 'link',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ link }) => link.replace(/.+\/\/|www.|\/.+/g, ''),
  },
  {
    title: 'from Private Account?',
    field: 'user.accountTypeId',
    emptyValue: 'No',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ user }) => [user.accountTypeId === 2 ? 'Yes' : 'No'],
    sorting: false,
  },
  {
    title: 'Category',
    field: 'category.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onUserDetails, onGiftDetails, onGiftPhotos, onGiftHide }) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
  (gift) => ({
    name: gift.isHidden ? 'Show' : 'Hide',
    onClick: onGiftHide,
  }),
];

const actionsForGiftAdmin = ({ onGiftDetails, onGiftPhotos, onGiftHide }) => [
  () => ({
    name: 'Gift details',
    onClick: onGiftDetails,
  }),
  (gift) => ({
    name: 'Photos',
    onClick: onGiftPhotos,
    isDisabled: !gift.photos.length,
  }),
  (gift) => ({
    name: gift.isHidden ? 'Show' : 'Hide',
    onClick: onGiftHide,
  }),
];

const giftsGetSearchParams = (columns, value) => {
  const fieldsNotSearch = [
    'follow',
    'photos',
    'isTimeout',
    'photo',
    'position',
    'user.accountTypeId',
    'currency',
    'description',
    'currency.code',
    'link',
    'category.name',
  ];
  const filteredColumns = columns.filter(
    (column) => !column.type?.includes('date') && !fieldsNotSearch.includes(column.field),
  );

  return filteredColumns.map((column) => {
    return {
      [column.field]: column.type === 'numeric' ? checkNumberValue(value) : { $contL: value },
    };
  });
};

export const GiftsPage = ({ history, match, location }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();

  const dispatch = useDispatch();
  const [selectedGifts, setSelectedGifts] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(undefined);

  const gifts = useSelector((state) => state.gifts);
  const categories = useSelector((state) => state.categories);
  const userRole = useSelector((state) => state.auth.user.role);
  const countries = useSelector((state) => state.deals.countries);
  const currency = useSelector((state) => state.currency);

  const [isPriorityOnly, setIsPriorityOnly] = useState(false);

  const isUserGifts = location.pathname.includes('users');

  const onUserDetails = (rowData) => history.push(`/admin/users/${rowData.user.id}`);

  const onGiftDetails = (rowData) => history.push(`/admin/gifts/${rowData.id}`);

  const onGiftHide = (rowData) => dispatch(updateGift(rowData.id, { isHidden: !rowData.isHidden }));

  const onRowClick = (event, rowData) => {
    const giftIDs = selectedGifts.includes(rowData.id)
      ? selectedGifts.filter((gift) => gift !== rowData.id)
      : [...selectedGifts, rowData.id];

    setSelectedGifts(giftIDs);
  };

  const onSubmit = (values) => {
    const filteredValues = Object.entries(values).filter((value) => value[1]);

    selectedGifts.forEach((gift) => dispatch(updateGift(gift, Object.fromEntries(filteredValues))));
    setSelectedGifts([]);
  };

  const onCreateGift = () => history.push(`${match.url}/create`);

  const onGiftPhotos = (rowData) => history.push(`/admin/gifts/${rowData.id}/photos`);

  const handlerSearchParams = useCallback(
    ({ params }) => ({
      $and: [
        { 'user.id': match.params.id },
        {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        {
          isPriority: isPriorityOnly ? { $eq: true } : {},
        },
        {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        selectedCurrency?.value && {
          'currency.code': { $eq: selectedCurrency?.value },
        },
        params,
      ],
    }),
    [match, startDate, endDate, isPriorityOnly, selectedCurrency],
  );

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCurrency());
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div>
      <h1 className={styles.title}>Gifts</h1>

      <div className={styles.actions}>
        {!isUserGifts && (
          <button type='button' onClick={onCreateGift} className={styles.button}>
            Create gift
          </button>
        )}

        <Checkbox
          label='Priority only'
          value={isPriorityOnly}
          checked={isPriorityOnly}
          onChange={() => setIsPriorityOnly(!isPriorityOnly)}
          className={styles.checkbox}
        />

        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        <SearchOptions
          currency={currency.list}
          onCurrencyChange={setSelectedCurrency}
          value={selectedCurrency}
        />
      </div>

      <GiftsForm
        countries={countries}
        categories={categories}
        selectedGifts={selectedGifts}
        onSubmit={onSubmit}
      />

      <Table
        data={gifts}
        getData={getGifts}
        columns={columns}
        onRowClick={onRowClick}
        defaultSort='createdAt,DESC'
        actions={
          userRole.id === 3
            ? actionsForGiftAdmin({ onGiftDetails, onGiftPhotos, onGiftHide })
            : actions({
                onUserDetails,
                onGiftDetails,
                onGiftPhotos,
                onGiftHide,
              })
        }
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: selectedGifts.includes(rowData.id) && 'rgba(0, 0, 0, 0.08)',
          }),
        }}
        searchParams={handlerSearchParams}
        advancedGetSearchParams={giftsGetSearchParams}
      />
    </div>
  );
};
