import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  data: null,
  isLoading: false,
};

// types
export const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';

// action creators
export const getDashboard = createAction(GET_DASHBOARD, api.dashboard.getDashboard);

// reducer
const dashboardReducer = handleActions(
  {
    [`${GET_DASHBOARD}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_DASHBOARD}_SUCCESS`]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
    }),

    [`${GET_DASHBOARD}_ERROR`]: () => initialState,
  },
  initialState,
);

export default dashboardReducer;
