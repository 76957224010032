import React, { useEffect } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getGift, updateGift } from '../../store/modules/gifts';
// components
import { GiftDetailsForm } from './GiftDetailsForm';
// styles
import styles from './GiftDetails.module.css';

export const GiftDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const gift = useSelector((state) => state.gifts.details);
  const categories = useSelector((state) => state.categories);
  const currency = useSelector((state) => state.currency);
  const countries = useSelector((state) => state.deals.countries);

  const onUpdateGift = (values) =>
    dispatch(updateGift(gift.id, { ...values, price: parseFloat(values.price) })).then(() =>
      history.goBack(),
    );

  useEffect(() => {
    dispatch(getGift(match.params.id));
  }, [dispatch, match]);

  if (!gift) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>
        #{gift.id} {gift.name}
      </h1>

      <GiftDetailsForm
        values={gift}
        onSubmit={onUpdateGift}
        categories={categories}
        currency={currency}
        countries={countries}
      />
    </div>
  );
};
