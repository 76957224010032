import axios from 'axios';
import { getAuthToken } from './authData';

export const baseURL = `${process.env.REACT_APP_API_URL}/api/v1`;

const axiosInstance = axios.create({
  baseURL,
});

/* request interceptor */
axiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = await getAuthToken();

    config.headers.authorization = `Bearer ${authToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);

/* response interceptor */
axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (responseData = {}) => {
    const { response, message } = responseData;
    const data = response.data;
    const status = response.status;

    return Promise.reject({
      data,
      message,
      status,
    });
  },
);

export default axiosInstance;
