import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../../store/modules/dashboard';
// components
import { Card } from './Card';
import { PieChart } from './PieChart';
import { PieChartLegend } from './PieChartLegend';
// styles
import styles from './Dashboard.module.css';

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard.data);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  const sortedCountries = useMemo(() => {
    if (dashboard) {
      const otherCountries = {
        name: 'Other',
        userCount: 0,
      };
      dashboard.userCountByCountry.forEach((item) => {
        if (item.userCount < 500) {
          otherCountries.userCount += item.userCount;
        }
      });
      if (otherCountries.userCount > 0) {
        const filteredCountries = dashboard.userCountByCountry.filter(
          ({ userCount, name }) => name && userCount > 500,
        );
        return [...filteredCountries, otherCountries];
      } else {
        return dashboard.userCountByCountry;
      }
    }
    return [];
  }, [dashboard]);

  if (!dashboard) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>Dashboard</h1>

      <div className={styles.cards}>
        <Card
          count={dashboard.userCount}
          text='Users'
          icon='user'
          link='/admin/users'
          color='#337ab7'
        />

        <Card
          count={dashboard.giftCount}
          text='Gifts'
          icon='gift'
          link='/admin/gifts'
          color='#60B85C'
        />
      </div>

      <div className={styles.charts}>
        <Grid container justify='center' className={styles.pieChartLegendContainer}>
          <PieChartLegend data={sortedCountries} legendData={dashboard.userCountByCountry} />
          <Grid container justify='center'>
            <span className={styles.chartTitle}>Users by Country</span>
          </Grid>
        </Grid>
        <Grid container justify='center' className={styles.pieChartContainer}>
          <PieChart data={dashboard.userCountByGender} />
          <Grid container justify='center'>
            <span className={styles.chartTitle}>Users by Gender</span>
          </Grid>
        </Grid>
        <Grid container justify='center' className={styles.pieChartContainer}>
          <PieChart data={dashboard.userCountByAgeGroup} />
          <Grid container justify='center'>
            <span className={styles.chartTitle}>Users by Age</span>
          </Grid>
        </Grid>
        <Grid container justify='center' className={styles.pieChartContainer}>
          <PieChart data={dashboard.userCountByPlatform} />
          <Grid container justify='center'>
            <span className={styles.chartTitle}>Users by OS</span>
          </Grid>
        </Grid>
        <Grid container justify='center' className={styles.pieChartContainer}>
          <PieChart data={dashboard.userGiftCount} />
          <Grid container justify='center'>
            <span className={styles.chartTitle}>Users by Gifts</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
