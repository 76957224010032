import React from 'react';
// store
import { useSelector, useDispatch } from 'react-redux';
import { uploadPhoto } from '../../store/modules/gifts';
import { updateCategory } from '../../store/modules/categories';
// components
import { CategoriesDetailsForm } from './CategoriesDetailsForm';
// styles
import styles from './CategoriesDetails.module.css';

export const CategoriesDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const category = useSelector((state) =>
    state.categories.list.find((category) => category.id === parseFloat(match.params.id)),
  );

  const onUpdateCategory = ({ photo, photoPreview, ...values }) => {
    if (photo.path) {
      dispatch(updateCategory(values.id, values)).then(() => history.goBack());
      return;
    }

    const formData = new FormData();
    const file = new File([photo], 'image.jpeg');

    formData.append('file', file);

    dispatch(uploadPhoto(formData)).then((response) => {
      dispatch(
        updateCategory(values.id, {
          ...values,
          photo: response.value,
        }),
      ).then(() => history.goBack());
    });
  };

  return (
    <div>
      <h1 className={styles.title}>Category: {category.name}</h1>

      <CategoriesDetailsForm values={category} onSubmit={onUpdateCategory} />
    </div>
  );
};
