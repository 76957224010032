import React, { useCallback, useMemo, useState } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, deleteUser, updateUser } from '../../store/modules/users';
import { getAuthToken } from 'utils/authData';
// components
import { Table } from '../../components';
import DatePickers from '../../components/DatePickers';
import Button from '@material-ui/core/Button';
// instanse
import { baseURL } from 'utils/axios.instance';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './Users.module.css';

export const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'First name',
    field: 'firstName',
    emptyValue: 'N/A',
  },
  {
    title: 'Last name',
    field: 'lastName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Email',
    field: 'email',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Birthday',
    field: 'birthDate',
    emptyValue: 'N/A',
    type: 'date',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Account Type',
    field: 'accountType',
    emptyValue: 'N/A',
    type: 'date',
    cellStyle: { whiteSpace: 'nowrap' },
    render: ({ accountType }) => [accountType?.name],
  },

  {
    title: 'Followers',
    field: 'followersCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Following',
    field: 'followingCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Gifts',
    field: 'giftCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Country',
    field: 'country.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Last activity',
    field: 'updatedAt',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Created account',
    field: 'createdAt',
    type: 'datetime',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Gender',
    field: 'gender.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  // {
  //   title: 'Age group',
  //   field: 'ageGroup',
  //   emptyValue: 'N/A',
  //   cellStyle: { whiteSpace: 'nowrap' },
  // },
  {
    title: 'Platform',
    field: 'platform.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Type',
    field: 'accountType.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Status',
    field: 'status.name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({
  onUserDetails,
  onUserGifts,
  onDeleteUser,
  toggleActivateUser,
  onUserFollowers,
  onUserFollowings,
  onBanUser,
  onUserDeals,
}) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
  () => ({
    name: 'Gifts',
    onClick: onUserGifts,
  }),
  (user) => ({
    name: 'Followers',
    onClick: onUserFollowers,
    isDisabled: !user.followersCount,
  }),
  (user) => ({
    name: 'Following',
    onClick: onUserFollowings,
    isDisabled: !user.followingCount,
  }),
  () => ({
    name: 'User Deals',
    onClick: onUserDeals,
  }),
  (user) => ({
    name: user.status.name === 'Inactive' ? 'Activate user' : 'Deactivate user',
    onClick: toggleActivateUser,
  }),
  (user) => ({
    name: user.isBanned ? 'Unban/Show' : 'Ban/Hide',
    onClick: onBanUser,
  }),
  () => ({
    name: 'Delete user',
    onClick: onDeleteUser,
  }),
];

export const UsersPage = ({ history, match }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();
  const [searchParams, setSearchParams] = useState({});

  const authToken = getAuthToken();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const onUserDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  const onUserFollowers = (rowData) => history.push(`${match.url}/${rowData.id}/followers`);

  const onUserFollowings = (rowData) => history.push(`${match.url}/${rowData.id}/followings`);

  const onUserGifts = (rowData) => history.push(`${match.url}/${rowData.id}/gifts`);

  const onUserDeals = (rowData) => history.push(`${match.url}/${rowData.id}/deals`);

  const toggleActivateUser = (rowData) =>
    dispatch(
      updateUser(rowData.id, {
        status: rowData.status.name === 'Inactive' ? { id: 1 } : { id: 2 },
      }),
    );

  const onBanUser = (rowData) =>
    dispatch(
      updateUser(rowData.id, {
        isBanned: !rowData.isBanned,
      }),
    );

  const onDeleteUser = (rowData, fetchData) =>
    dispatch(deleteUser(rowData.id)).then(() =>
      fetchData(users.count === 1 ? users.page - 1 : users.page, users.count),
    );

  const handlerSearchParams = useCallback(
    ({ params, search }) => {
      const userSearch = {
        $and: [
          !search && {
            createdAt: {
              $gte: startDate.toISOString(),
            },
          },
          !search && {
            createdAt: {
              $lte: endDate.toISOString(),
            },
          },
          {
            '"role"."id"': 1,
          },
          params,
        ],
      };

      setSearchParams(userSearch);

      return userSearch;
    },
    [startDate, endDate],
  );

  const exportUsersLink = useMemo(
    () => `${baseURL}/users/csv?bearerToken=${authToken}&s=${JSON.stringify(searchParams)}`,
    [authToken, searchParams],
  );

  return (
    <div>
      <h1 className={styles.title}>Users</h1>

      <div className={styles.actions}>
        <DatePickers
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <Button color='primary' variant='contained' size='small' className={styles.exportButton}>
          <a href={exportUsersLink}>Export users</a>
        </Button>
      </div>

      <Table
        data={users}
        columns={columns}
        getData={getUsers}
        searchParams={handlerSearchParams}
        defaultSort='createdAt,DESC'
        actions={actions({
          onUserDetails,
          onUserGifts,
          onDeleteUser,
          toggleActivateUser,
          onUserFollowers,
          onUserFollowings,
          onBanUser,
          onUserDeals,
        })}
        options={{ exportFileName: 'Users' }}
      />
    </div>
  );
};
