import React from 'react';
// store
import { useDispatch } from 'react-redux';
import { uploadPhoto } from '../../store/modules/gifts';
import { createCategory } from '../../store/modules/categories';
// components
import { CategoriesDetailsForm } from '../CategoriesDetails/CategoriesDetailsForm';
// styles
import styles from './CategoriesCreate.module.css';

export const CategoriesCreatePage = ({ history }) => {
  const dispatch = useDispatch();

  const onCreateCategory = ({ photo, photoPreview, ...values }) => {
    if (!photo) {
      return;
    }
    const formData = new FormData();
    const file = new File([photo], 'image.jpeg');

    formData.append('file', file);

    dispatch(uploadPhoto(formData)).then((response) => {
      dispatch(
        createCategory({
          ...values,
          photo: response.value,
        }),
      ).then(() => history.goBack());
    });
  };

  return (
    <div>
      <h1 className={styles.title}>Create category</h1>

      <CategoriesDetailsForm onSubmit={onCreateCategory} />
    </div>
  );
};
