import React, { useEffect } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser } from '../../store/modules/users';
// components
import { AdminDetailsForm } from './AdminDetailsForm';
// styles
import styles from './AdminDetails.module.css';

export const AdminDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.users.user);

  const onUpdateAdmin = ({ password, ...values }) => {
    delete values.email;

    const newValues = password ? { ...values, password } : values;

    dispatch(updateUser(admin.id, newValues)).then(() => history.goBack());
  };

  useEffect(() => {
    dispatch(getUser(match.params.id));
  }, [dispatch, match]);

  if (!admin) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>
        #{admin.id} {admin.email}
      </h1>

      <AdminDetailsForm values={admin} onSubmit={onUpdateAdmin} />
    </div>
  );
};
