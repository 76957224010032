import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_GIFT_SALES = 'giftSales/GET_GIFT_SALES';

// action creators
export const getGiftSales = createAction(GET_GIFT_SALES, api.giftSales.getGiftSales);

// reducer
const giftSalesReducer = handleActions({
    [`${GET_GIFT_SALES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_GIFT_SALES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),
  },
  initialState,
);

export default giftSalesReducer;
