import Resizer from 'react-image-file-resizer';

export const checkNumberValue = (value) => (!Number.isNaN(Number(value)) ? value : undefined);

export const resizeImage = (file, width, height) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob',
    );
  });
