import React from 'react';
// formik
import { useFormik } from 'formik';
// components
import Select from 'react-select';
// styles
import styles from './Gifts.module.css';

const getOptions = (options) =>
  options?.map((option) => ({
    value: option.name.toLowerCase(),
    label: option.name,
    ...option,
  }));

export const GiftsForm = ({ categories, countries, selectedGifts, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      category: null,
      countries: null,
    },
    onSubmit,
  });

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <Select
        name='category'
        placeholder='Select a category...'
        options={getOptions(categories.list)}
        onChange={(value) => formik.setFieldValue('category', value)}
        className={styles.select}
      />

      <Select
        isMulti
        name='countries'
        placeholder='Select a countries...'
        options={getOptions(countries)}
        onChange={(value) => formik.setFieldValue('countries', value)}
        className={`${styles.select} ${styles.selectCountries}`}
      />

      <button type='submit' className={styles.button} disabled={!selectedGifts.length}>
        Save changes
      </button>
    </form>
  );
};
