import React, { useCallback } from 'react';
// store
import { useSelector } from 'react-redux';
import { getCategories } from '../../store/modules/categories';
// components
import { Table } from '../../components';
// styles
import styles from './Categories.module.css';

const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    width: 100,
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Photo',
    field: 'photo',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', maxWidth: '200px' },
    sorting: false,
    render: ({ photo }) => {
      return photo ? <img src={photo.path} alt='Gift' className={styles['gift-photo']} /> : 'N/A';
    },
  },
  {
    title: 'Name',
    field: 'name',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Created',
    field: 'createdAt',
    type: 'datetime',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Gift Count',
    field: 'giftCount',
    emptyValue: 'N/A',
    type: 'numeric',
    sorting: false,
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Position',
    field: 'position',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onCategoryDetails, onCategoryGifts }) => [
  () => ({
    name: 'Category details',
    onClick: onCategoryDetails,
  }),
  () => ({
    name: 'Gifts',
    onClick: onCategoryGifts,
  }),
];

export const CategoriesPage = ({ match, history }) => {
  const categories = useSelector((state) => state.categories);

  const onCategoryDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  const onCategoryGifts = (rowData) => history.push(`${match.url}/${rowData.id}/gifts`);

  const onCreateCategory = () => history.push(`${match.url}/create`);

  const onCategoriesPosition = () => history.push(`${match.url}/positions`);

  const handlerSearchParams = useCallback(({ params }) => ({ $and: [params] }), []);

  return (
    <div>
      <h1 className={styles.title}>Categories</h1>

      <button type='button' className={styles.button} onClick={onCreateCategory}>
        Create category
      </button>
      <button onClick={onCategoriesPosition} type='button' className={styles.button}>
        Position
      </button>

      <Table
        data={categories}
        getData={getCategories}
        columns={columns}
        searchParams={handlerSearchParams}
        actions={actions({ onCategoryDetails, onCategoryGifts })}
        defaultSort='createdAt,DESC'
        defaultPageSize={50}
      />
    </div>
  );
};
