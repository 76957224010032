import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck, faStar, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './NavBar.module.css';
// store
import { logOutRequest } from '../../store/modules/auth';
import { clearAuthToken } from '../../utils/authData';

export const NavBar = () => {
  const match = useRouteMatch();
  const adminRole = useSelector((state) => state.auth.user.role.name);
  const dispatch = useDispatch();

  const links = {
    dashboard: {
      to: `${match.url}/dashboard`,
      icon: 'tachometer-alt',
      name: 'Dashboard',
    },
    users: {
      to: `${match.url}/users`,
      icon: 'users',
      name: 'Users',
    },
    influencers: {
      to: `${match.url}/influencers`,
      icon: faStar,
      name: 'Influencers',
    },
    usersSales: {
      to: `${match.url}/users-sales`,
      icon: faUserCheck,
      name: 'User\'s sales',
    },
    giftsSales: {
      to: `${match.url}/gifts-sales`,
      icon: faMoneyCheck,
      name: 'Gift\'s sales',
    },
    gifts: {
      to: `${match.url}/gifts`,
      icon: 'gift',
      name: 'Gifts',
    },
    topGifts: {
      to: `${match.url}/top-gifts`,
      icon: 'gift',
      name: 'Top Gifts',
    },
    reports: {
      to: `${match.url}/reports`,
      icon: 'list-alt',
      name: 'Reports',
    },
    parserRules: {
      to: `${match.url}/parser-rules`,
      icon: 'eye-dropper',
      name: 'Parser rules',
    },
    parserFails: {
      to: `${match.url}/parser-fails`,
      icon: 'save',
      name: 'Parser fails',
    },
    categories: {
      to: `${match.url}/categories`,
      icon: 'bolt',
      name: 'Categories',
    },
    deals: {
      to: `${match.url}/deals`,
      icon: 'bell',
      name: 'Deals',
    },
    settings: {
      to: `${match.url}/settings`,
      icon: 'wrench',
      name: 'System settings',
    },
  };

  const linksForAdminRole = {
    'Gift Admin': [links.gifts, links.categories, links.deals],
    'Parser Admin': [links.parserRules, links.parserFails],
    Admin: Object.values(links),
  };

  const logOut = () => {
    try {
      clearAuthToken();
      dispatch(logOutRequest());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <nav className={styles.navbar}>
      {linksForAdminRole[adminRole].map((item, index) => (
        <NavLink key={index} to={item.to} activeClassName={styles.active} className={styles.link}>
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
          {item.name}
        </NavLink>
      ))}
      <div className={styles.link} onClick={logOut}>
        <FontAwesomeIcon icon={'wrench'} className={styles.icon} />
        Log out
      </div>
    </nav>
  );
};
