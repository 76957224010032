import React, { useCallback } from 'react';
// store
import { useSelector } from 'react-redux';
import { getFollowings } from '../../store/modules/followers';
// components
import { Table } from '../../components';
import { columns } from '../Users/Users';
import DatePickers from '../../components/DatePickers';
// hooks
import useDatePickers from '../../components/DatePickers/useDatePicker';
// styles
import styles from './Followings.module.css';

const actions = ({ onUserDetails }) => [
  () => ({
    name: 'User details',
    onClick: onUserDetails,
  }),
];

export const FollowingsPage = ({ history, match }) => {
  const followings = useSelector((state) => state.followers);
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickers();

  const onUserDetails = (rowData) => history.push(`/admin/users/${rowData.id}`);

  const handlerSearchParams = useCallback(
    ({ params, search }) => ({
      $and: [
        { 'follower.id': match.params.id },
        !search && {
          createdAt: {
            $gte: startDate.toISOString(),
          },
        },
        !search && {
          createdAt: {
            $lte: endDate.toISOString(),
          },
        },
        params,
      ],
    }),
    [match, startDate, endDate],
  );

  return (
    <div>
      <h1 className={styles.title}>Followings</h1>
      <DatePickers
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultSort='createdAt,DESC'
      />

      <Table
        getData={getFollowings}
        data={followings}
        columns={columns}
        actions={actions({ onUserDetails })}
        searchParams={handlerSearchParams}
      />
    </div>
  );
};
