import React from 'react';
// store
import { useSelector } from 'react-redux';
import { getUsersSales } from '../../store/modules/usersSales';
// styles
import styles from '../Influencers/Influencers.module.css';
import { Table } from '../../components';

export const columns = [
  {
    title: 'ID',
    field: 'id',
    emptyValue: 'N/A',
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'First name',
    field: 'firstName',
    emptyValue: 'N/A',
  },
  {
    title: 'Last name',
    field: 'lastName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Email',
    field: 'email',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Gifts',
    field: 'giftCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Country',
    field: 'countryName',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Sales',
    field: 'salesCount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
    headerStyle: { textAlign: 'left', flexDirection: 'row' },
    type: 'numeric',
  },
  {
    title: 'Revenue',
    field: 'totalCommissionAmount',
    emptyValue: 'N/A',
    cellStyle: { whiteSpace: 'nowrap' },
    type: 'numeric',
    render: ({ totalCommissionAmount }) => [Math.trunc(totalCommissionAmount * 100) / 100],
  },
  {
    title: 'Last sale at',
    field: 'lastSaleAt',
    emptyValue: 'N/A',
    type: 'datetime',
    cellStyle: { whiteSpace: 'nowrap' },
  },
];

const actions = ({ onUserSalesDetails }) => [
  () => ({
    name: 'User sales details',
    onClick: onUserSalesDetails,
  }),
];

export const UsersSales = ({ history, match }) => {
  const users = useSelector((state) => state.usersSales);

  const onUserSalesDetails = (rowData) => history.push(`${match.url}/${rowData.id}`);

  return (
    <div>
      <h1 className={styles.title}>User's Sales</h1>

      <Table
        data={users}
        columns={columns}
        getData={getUsersSales}
        defaultSort='lastSaleAt,DESC'
        actions={actions({onUserSalesDetails})}
      />
    </div>
  );
};
