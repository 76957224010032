import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

// types
export const GET_USER_SALES = 'userSales/GET_USER_SALES';

// action creators
export const getUserSales = createAction(GET_USER_SALES, api.userSales.getUserSales);

// reducer
const userSalesReducer = handleActions({
    [`${GET_USER_SALES}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_USER_SALES}_SUCCESS`]: (state, { payload: { data, ...rest } }) => ({
      ...state,
      ...rest,
      list: data,
      isLoading: false,
    }),
  },
  initialState,
);

export default userSalesReducer;
