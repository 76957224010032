import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import styles from './Form.module.css';

export const DateTimePicker = ({ error, ...rest }) => (
  <div className={styles.datePicker}>
    <KeyboardDateTimePicker {...rest} />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);
