import { combineReducers } from 'redux';
// modules
import auth from './modules/auth';
import users from './modules/users';
import gifts from './modules/gifts';
import categories from './modules/categories';
import parserRules from './modules/parserRules';
import parserFails from './modules/parserFails';
import followers from './modules/followers';
import deals from './modules/deals';
import stores from './modules/stores';
import reports from './modules/reports';
import dashboard from './modules/dashboard';
import currency from './modules/currency';
import userDeals from './modules/userDeals';
import usersSales from './modules/usersSales';
import giftsSales from './modules/giftsSales';
import giftSales from './modules/giftSales';
import userSales from './modules/userSales';

const appReducer = combineReducers({
  auth,
  users,
  usersSales,
  userSales,
  gifts,
  giftsSales,
  giftSales,
  categories,
  parserRules,
  parserFails,
  followers,
  deals,
  stores,
  reports,
  dashboard,
  currency,
  userDeals,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
