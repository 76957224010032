import { useState, useMemo } from 'react';

const initStartDate = new Date(new Date().setMonth(new Date().getMonth() - 2));
const initEndDate = new Date();

const useDatePickers = (start = initStartDate, end = initEndDate) => {
  const [startD, setStartDate] = useState(start);
  const [endD, setEndDate] = useState(end);

  const startDate = useMemo(() => new Date(startD.setHours(0, 0, 0)), [startD]);

  const endDate = useMemo(() => new Date(endD.setHours(23, 59, 59)), [endD]);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
};

export default useDatePickers;
