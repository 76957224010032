import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = {
  list: [],
  total: 0,
  page: 1,
  pageCount: 0,
  count: 0,
  isLoading: false,
};

export const GET_CURRENCY = 'currency/GET_CURRENCY';

// action creators
export const getCurrency = createAction(GET_CURRENCY, api.currency.getCurrency);

// reducer
const currencyReducer = handleActions(
  {
    [`${GET_CURRENCY}_REQUEST`]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [`${GET_CURRENCY}_SUCCESS`]: (state, { payload }) => {
      const { data, ...rest } = payload;

      return {
        ...rest,
        list: Object.values(payload),
        isLoading: false,
      };
    },

    [`${GET_CURRENCY}_ERROR`]: () => initialState,
  },
  initialState,
);

export default currencyReducer;
