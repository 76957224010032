import React, { useEffect } from 'react';
// store
import { useDispatch, useSelector } from 'react-redux';
import { testParserRule, clearTestParserRule } from '../../store/modules/parserRules';
// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
// components
import { Input } from '../../components';
import CircularProgress from '@material-ui/core/CircularProgress';
// styles
import styles from './ParserRulesDetails.module.css';

const validationSchema = Yup.object().shape({
  url: Yup.string().matches(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    'Invalid url',
  ),
});

export const ParserRulesTestAddressForm = () => {
  const dispatch = useDispatch();
  const parsedRule = useSelector((state) => state.parserRules.parsed);
  const isLoading = useSelector((state) => state.parserRules.isLoading);

  const formik = useFormik({
    initialValues: { url: '' },
    validationSchema,
    onSubmit: (values) => dispatch(testParserRule(values)),
  });

  useEffect(() => () => dispatch(clearTestParserRule()), [dispatch]);

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <h2 className={styles.sectionTitle}>Test address</h2>

      <Input
        // label="Test address"
        name='url'
        placeholder='Address'
        onChange={formik.handleChange}
        value={formik.values.url}
        error={formik.touched.url && formik.errors.url}
      />

      {isLoading && <CircularProgress className={styles.loader} />}
      {parsedRule && (
        <div className={styles.parsedWrapper}>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>title</div>
            <div className={styles.parsedValue}>{parsedRule.title}</div>
          </div>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>description</div>
            <div className={styles.parsedValue}>{parsedRule.description}</div>
          </div>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>price</div>
            <div className={styles.parsedValue}>{parsedRule.price}</div>
          </div>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>currency</div>
            <div className={styles.parsedValue}>{parsedRule.currency}</div>
          </div>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>images</div>
            <div className={styles.parsedValue}>{parsedRule.images.join('\n')}</div>
          </div>
          <div className={styles.parsedRow}>
            <div className={styles.parsedKey}>isSoldOut</div>
            <div className={styles.parsedValue}>{parsedRule.isSoldOut.toString()}</div>
          </div>
        </div>
      )}

      <button type='submit' className={styles['button-submit']}>
        Test
      </button>
    </form>
  );
};
